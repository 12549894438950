import React, { useEffect } from 'react';
import { VideoSection } from '../sections';
import { Section } from '../../common';
import MeetingsOverview from '../../greatTeachingTeams/components/MeetingsOverview';
import { Me } from '../../../@types/Entity/Me';
import GTTNiotLogo from '@app/asset/images/GTT_NIoT.png';
import { LinkStyled } from '../../../global/link/link.styled';
import { useQuery } from 'react-query';
import { THINKIFIC_SSO } from '../../../service/queryKeys';
import { getThinkificSSO } from '../../../service/api';
import { THINKIFIC_LINKS } from '../../../service/links';
import { ROLE_SCHOOL_COORDINATOR, ROLE_TEACHER } from '../../../resources/roles';
import SelfStudy from '../../greatTeachingTeamsWorkspace/components/SelfStudy';

const NiotCeoDashboard = ({ me }: { me: Me }) => {
  const thinkificSSO = useQuery(THINKIFIC_SSO, () => getThinkificSSO(), {
    select: (data: any) => data.data.data.link,
    refetchInterval: 120000, //Thinkifc token last for 2 mins,so we refetch the token here every 2 mins
    refetchIntervalInBackground: true,
  });

  const data = () => {
    if (me.is_ceo && ROLE_TEACHER === me.role && !me.is_ceo_admin) {
      return {
        title: 'School Trust CEO Programme (STCP) with the National Institute of Teaching',
        videoLink: 'https://vimeo.com/906776082/e012db567e?share=copy',
        content: (
          <>
            <Section size={'md'} containerClassName={'h-full'} className={'field-mb'}>
              <VideoSection video={'https://vimeo.com/906776082/e012db567e?share=copy'} />
              <br />
              <p className={'font-bold'}>
                Welcome to the School Trust CEO Programme with the National Institute of Teaching (NIoT). We’re
                delighted to have you on board!
              </p>
              <br />
              <p className={'font-bold'}>
                Start by watching your welcome video (above) and then navigate to the STCP Induction to learn more about
                how the programme works.
              </p>
              <br />
              <div>
                <LinkStyled
                  asButton
                  mainColor={'primary'}
                  href={thinkificSSO.isFetched ? thinkificSSO.data + THINKIFIC_LINKS['stcpIntroduction'] : '#'}
                  target={'_blank'}
                  className={'mx-auto inline-block'}
                >
                  STCP INDUCTION
                </LinkStyled>
              </div>
              <br />
              <div className={'flex justify-center'}>
                <img src={GTTNiotLogo} width={'50%'} height={'auto'} alt={'niot logo'} />
              </div>
            </Section>
            <SelfStudy stcp me={me} groupId={null} />
            <Section size={'md'} className={'field-mb mt-8'}>
              <h5 className={'mb-8'}>The Great Teaching Toolkit</h5>
              <div className="mb-4">
                <p className={'mb-8'}>
                  The Great Teaching Toolkit, by Evidence Based Education, is a platform partner to the National
                  Institute of Teaching, on both the School Trust CEO Programme and the Early Career Framework.
                </p>
                <p className={'mb-12'}>
                  The GTT in its original form provides everything schools need, to get better together, and has been
                  adapted to suit the needs of these specific programmes.
                </p>
                <LinkStyled
                  href={
                    'https://evidencebased.education/great-teaching-toolkit-cpd/great-teaching-toolkit-school-trust-ceo-programme/'
                  }
                  asButton
                  target={'_blank'}
                  className={'mb-4'}
                  mainColor={'primary'}
                >
                  Learn more
                </LinkStyled>
              </div>
            </Section>
          </>
        ),
      };
    }

    if (me.is_ceo && ROLE_SCHOOL_COORDINATOR === me.role) {
      return {
        title: 'Welcome to your school’s CEO with the National Institute of Teaching (NIoT).',
        welcomeVideo: 'https://vimeo.com/906776082/e012db567e?share=copy',
        videoLink: 'https://vimeo.com/906776082/e012db567e?share=copy',
        content: (
          <Section size={'md'} containerClassName={'h-full'} className={'col-span-2'}>
            <VideoSection video={'https://vimeo.com/906776082/e012db567e?share=copy'} />
            <br />
            <p className={'font-bold'}>
              We value the role of our CEO coordinator extremely highly and have developed a programme and platform to
              ensure that you can easily manage the progress of your CEOs in one place.
            </p>
            <br />
            <p className={'font-bold'}>To begin, please watch the welcome video from ......, Head of CEO Faculty.</p>
            <br />
            <div>
              <LinkStyled
                asButton
                href={
                  'https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/DPS_GTT%20Resources/Induction%20lead%20Introduction%20booklet%202023-1.pdf'
                }
                target={'_blank'}
                className={'mx-auto inline-block'}
              >
                Introduction booklet ......
              </LinkStyled>
            </div>
            <br />
            <div className={'flex justify-center'}>
              <img src={GTTNiotLogo} width={'50%'} height={'auto'} alt={'niot logo'} />
            </div>
          </Section>
        ),
      };
    }

    if (me.is_ceo && me.is_ceo_admin) {
      return {
        title: 'Welcome to our MAT CEO programme with the Great Teaching Toolkit',
        content: (
          <Section size={'md'} containerClassName={'h-full'} className={'col-span-3'}>
            <p className={'font-bold text-center'}>
              We’ve developed a programme and platform to ensure that you can easily manage the progress of your
              Schools, CEOs in one place.
            </p>
            <br />
            <div className={'flex justify-center my-8'}>
              <img src={GTTNiotLogo} width={'50%'} height={'auto'} alt={'niot logo'} />
            </div>
          </Section>
        ),
      };
    }
  };

  return (
    <>
      <TagSection me={me} title={data()!.title} content={data()!.content} />
    </>
  );
};

const TagSection = ({ me, title, content }: { me: Me; title: string; content: React.ReactElement }) => {
  return (
    <>
      <div className={'block-with-sub-container w-2/3 mb-5'}>
        <h5>{title}</h5>
        <div className={'gap-4 mt-5'}>{content}</div>
      </div>
    </>
  );
};

export default NiotCeoDashboard;
