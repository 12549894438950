import React, { MutableRefObject, useRef, useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { Link, useLocation } from 'react-router-dom';
import routeBuilder, { PathType } from '../../service/routeBuilder';
import { useOnClickOutside } from '../../hooks/useClickOutside';
import { IconType } from '../../global/icon/icon.types';
import Icon from '../../global/icon/icon';
import { MenuHamburger } from '../../global/hamburger';
import GTT_LOGO from '@app/asset/images/Great_Teaching_Toolkit_Logo.png';
import { DEFAULT_TRANSITION_DURATION, DEFAULT_TRANSITION_FUNC } from '../../global/transitions/transitions.types';
import { useQuery } from 'react-query';
import { getMe, getThinkificSSO } from '../../service/api';
import {
  isStcp,
  niotRole,
  ROLE_ASSISTANT_GT_COORDINATOR,
  ROLE_MAT_COORDINATOR,
  ROLE_SCHOOL_COORDINATOR,
  ROLE_SYS_ADMIN,
  ROLE_TEACHER,
  ROLE_TEACHER_FREE,
  RoleDescriptions,
  TAG_STCP_ADMIN,
  TAG_STCP_TEACHER,
} from '../../resources/roles';
import { ME, THINKIFIC_SSO } from '../../service/queryKeys';
import { THINKIFIC_LINKS } from '../../service/links';
import Tooltip from '../../global/tooltip/tooltip';
import { LinkStyled } from '../../global/link/link.styled';

type AsideContentType = {
  responsiveWidth: number;
  version: 'dark' | 'light';
};

type NavItemType = {
  id: PathType | 'courses' | 'selfStudy';
  params?: string[];
  path: string;
  label: string;
  icon: IconType | null;
  condition?: boolean;
  items?: NavItemType[];
  locked?: boolean;
  isolated?: boolean;
  tooltip?: any;
  hidden?: boolean;
  isFree?: boolean;
};

type SectionType = {
  section: string;
  condition?: boolean;
  items: NavItemType[];
  tooltip?: React.ReactNode;
};

const VERSIONS: Record<'dark' | 'light', { logo: any; background: string; text: string }> = {
  dark: {
    logo: GTT_LOGO,
    background: 'bg-gray-600',
    text: 'text-white',
  },
  light: {
    logo: GTT_LOGO,
    background: 'bg-gray-300',
    text: 'text-secondary',
  },
};

const STONLY_ITEMS_IDS = [
  'organisationManagement',
  'classManagement',
  'studentSurveys',
  'home',
  'videoObservation',
  'schoolEnv',
  'schoolEnvPage',
  'cpdPassport',
];

const FEEDBACK_PATH = '/feedback';
const ICON_SIZE = 30;

const AsideContent = ({ responsiveWidth, version }: AsideContentType): JSX.Element => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>;

  const { data: me } = useQuery(ME, getMe, {
    staleTime: Infinity,
    select: (data) => data.data,
  });

  const userRole = me!.data.me.role;

  const thinkificSSO = useQuery(THINKIFIC_SSO, () => getThinkificSSO(), {
    select: (data: any) => data.data.data.link,
    refetchInterval: 120000, //Thinkifc token last for 2 mins,so we refetch the token here every 2 mins
    refetchIntervalInBackground: true,
  });

  const userTag = niotRole(me?.data?.me);

  const stcpTag = isStcp(me?.data?.me);

  const isNiotAndStcpAdmin = userTag === 'NIOT' && stcpTag === TAG_STCP_ADMIN;

  const isFree = userRole === ROLE_TEACHER_FREE;

  const NAV_ITEMS: SectionType[] = [
    {
      section: ROLE_SYS_ADMIN === userRole ? 'Main' : '',
      items: [
        { id: 'home', path: '/', label: 'Dashboard', icon: 'GridFill' },
        {
          id: 'userManagement',
          path: '/user-management',
          label: 'Early Career Framework',
          icon: 'PeopleFill',
          condition:
            [ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) &&
            me!.data.me.is_induction_lead,
          tooltip:
            me!.data.me.is_induction_lead && me!.data.me.mentor
              ? 'Pair mentors and ECTs and monitor their progress!'
              : undefined,
        },
        {
          id: 'mentoringTeams',
          label: ROLE_TEACHER === userRole ? 'Early Career Framework' : 'Mentoring',
          path: '/mentoring-teams',
          icon: 'Diagram2Fill',
          condition: me!.data.me.mentor,
          tooltip:
            me!.data.me.is_induction_lead && me!.data.me.mentor
              ? 'For if you’re a mentor as well as an induction tutor'
              : undefined,
        },
        {
          id: 'mentoringTeamsWorkspace',
          params: [me!.data.me.mentee_group_id!],
          label: 'Early Career Framework',
          path: `/mentoring-teams/${me!.data.me.mentee_group_id}`,
          icon: 'Diagram2Fill',
          condition: ROLE_TEACHER === userRole && !me!.data.me.mentor && null != me!.data.me.mentee_group_id,
        },
        {
          id: 'monitoring',
          label: 'Monitoring',
          path: '/monitoring',
          icon: 'PeopleFill',
          condition:
            ROLE_SYS_ADMIN === userRole ||
            me!.data.me.is_associate_college ||
            me!.data.me.is_regional_lead ||
            me!.data.me.is_niot ||
            me!.data.me.is_ceo_admin,
        },
        {
          id: 'selfStudy',
          label: 'Self-study',
          path: THINKIFIC_LINKS['courses'],
          icon: 'FileSlides',
          condition:
            (ROLE_TEACHER === userRole && me!.data.me.is_early_career_teacher) ||
            me!.data.me.mentor ||
            me!.data.me.is_ceo ||
            isNiotAndStcpAdmin,
          tooltip:
            me!.data.me.is_induction_lead && me!.data.me.mentor
              ? 'Mentor self-study for if you’re a mentor as well as an induction tutor'
              : undefined,
        },
        {
          id: 'modelForGreatTeaching',
          path: '/model-for-great-teaching',
          label: 'Model for Great Teaching',
          icon: 'Box',
          condition: [ROLE_SYS_ADMIN].includes(userRole),
        },
        {
          id: 'userManagement',
          path: '/user-management',
          label: 'User Management',
          icon: 'PeopleFill',
          condition: [ROLE_SYS_ADMIN].includes(userRole),
        },
        {
          id: 'schoolManagement',
          path: '/school-management',
          label: 'School Management',
          icon: 'PersonLinesFill',
          condition: [ROLE_SYS_ADMIN].includes(userRole),
        },
        {
          id: 'notificationManagement',
          path: '/notification-management',
          label: 'Notification Management',
          icon: 'BellFill',
          condition: [ROLE_SYS_ADMIN].includes(userRole),
        },
        // {
        //   id: 'eventsManagement',
        //   path: '/events-management',
        //   label: 'Professional development events',
        //   icon: 'BellFill',
        //   condition:
        //     [ROLE_SYS_ADMIN, ROLE_MAT_COORDINATOR, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(
        //       userRole
        //     ) ||
        //     me?.data.me.is_niot ||
        //     me?.data.me.is_regional_lead ||
        //     me?.data.me.is_associate_college,
        // },
        {
          id: 'organisationManagement',
          path: '/organisation-management',
          label: 'School planner',
          icon: 'FolderFill',
          condition: [ROLE_SYS_ADMIN].includes(userRole),
        },
        {
          id: 'webinarManagementPage',
          path: '/zoom-management-page',
          label: 'Zoom Management',
          icon: 'CameraVideo',
          condition: [ROLE_SYS_ADMIN].includes(userRole),
        },
        {
          id: 'communityDashboard',
          path: '/community-dashboard',
          label: 'Community dashboard',
          icon: 'People',
          condition: [ROLE_SYS_ADMIN].includes(userRole),
        },
        {
          id: 'resourceDashboard',
          path: '/resource-dashboard',
          label: 'Resource Dashboard',
          icon: 'Book',
          condition: [ROLE_SYS_ADMIN].includes(userRole),
        },
      ],
    },
    {
      tooltip:
        stcpTag === TAG_STCP_TEACHER ? (
          <>
            These GTT tools are designed to help teachers improve their classroom practice, based on the Model for Great
            Teaching.{' '}
            <u>
              <LinkStyled
                href="https://evidencebased.education/great-teaching-toolkit-cpd/great-teaching-toolkit-school-trust-ceo-programme/"
                target={'_blank'}
              >
                Learn more
              </LinkStyled>
            </u>
          </>
        ) : undefined,
      section: 'My professional development',
      condition:
        ([ROLE_TEACHER_FREE, ROLE_TEACHER, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) &&
          (!stcpTag || stcpTag === TAG_STCP_TEACHER)) ||
        isNiotAndStcpAdmin,
      items: [
        {
          id: 'organisationManagement',
          path: '/organisation-management',
          label: userRole === ROLE_TEACHER && me!.data.me.school_user_id == null ? 'Setup' : 'School planner',
          icon: 'FolderFill',
          condition:
            [ROLE_SYS_ADMIN].includes(userRole) || (userRole === ROLE_TEACHER && me!.data.me.school_user_id == null),
        },
        {
          id: 'resourcePage',
          path: '/resource-page',
          label: 'Resources',
          icon: 'Search',
          condition: stcpTag === TAG_STCP_TEACHER,
        },
        {
          id: 'classManagement',
          path: '/class-management',
          label: 'Classes',
          isolated: stcpTag === TAG_STCP_TEACHER,
          icon: 'JournalText',
          condition:
            ([ROLE_TEACHER, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) &&
              (!stcpTag || stcpTag === TAG_STCP_TEACHER)) ||
            isNiotAndStcpAdmin,
        },
        {
          id: 'pathways',
          label: 'Development cycles',
          path: '/pathways',
          icon: 'Diagram2Fill',
          condition:
            ([ROLE_SCHOOL_COORDINATOR, ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_ASSISTANT_GT_COORDINATOR].includes(
              userRole
            ) &&
              !['MENTOR', 'ECT'].includes(userTag) &&
              (!stcpTag || stcpTag === TAG_STCP_TEACHER)) ||
            isNiotAndStcpAdmin,
          isolated:
            ['NIOT', 'REGIONAL_LEAD', 'ASSOCIATE_COLLEGE', 'INDUCTION_LEAD'].includes(userTag) ||
            stcpTag === TAG_STCP_TEACHER ||
            isNiotAndStcpAdmin,
          items: [
            {
              id: 'pathwaysHistory',
              label: 'Development cycles history',
              path: '/pathways/history',
              icon: 'ArrowReturnRight',
              condition:
                [ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(
                  userRole
                ) && !me!.data.me.is_ceo,
            },
          ],
        },
        {
          id: 'greatTeachingTeams',
          label: 'Great Teaching Teams',
          path: '/great-teaching-teams',
          icon: 'PeopleFill',
          isolated: stcpTag === TAG_STCP_TEACHER,
          condition:
            ([ROLE_SCHOOL_COORDINATOR, ROLE_TEACHER, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) &&
              !me!.data.me.mentor &&
              !me!.data.me.is_early_career_teacher &&
              !me!.data.me.is_induction_lead &&
              (!stcpTag || stcpTag === TAG_STCP_TEACHER)) ||
            isNiotAndStcpAdmin ||
            isFree,
        },
        {
          id: 'resourcePage',
          path: '/resource-page',
          label: 'Resources',
          icon: 'Search',
          condition:
            ([ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR, ROLE_TEACHER, ROLE_TEACHER_FREE].includes(
              userRole
            ) &&
              !stcpTag) ||
            isNiotAndStcpAdmin,
          locked: ROLE_TEACHER_FREE === userRole && localStorage.getItem('emulateStarter') === null,
        },
        {
          id: 'modelForGreatTeaching',
          path: '/model-for-great-teaching',
          label: 'Model for Great Teaching',
          icon: 'Box',
          isolated: stcpTag === TAG_STCP_TEACHER,
          condition:
            ([ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR, ROLE_TEACHER].includes(userRole) &&
              (!stcpTag || stcpTag === TAG_STCP_TEACHER)) ||
            isNiotAndStcpAdmin ||
            isFree,
        },
        {
          id: 'courses',
          label: 'Courses',
          path: THINKIFIC_LINKS['courses'],
          icon: 'FileSlides',
          isolated: stcpTag === TAG_STCP_TEACHER,
          condition:
            ([ROLE_SCHOOL_COORDINATOR, ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_ASSISTANT_GT_COORDINATOR].includes(
              userRole
            ) &&
              !['ECT'].includes(userTag) &&
              (!stcpTag || stcpTag === TAG_STCP_TEACHER)) ||
            isNiotAndStcpAdmin,
        },
        {
          id: 'cpdPassport',
          path: '/cpd-passport',
          label: 'Passport',
          icon: 'FilePersonFill',
          isolated: stcpTag === TAG_STCP_TEACHER,
          condition:
            ([ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(
              userRole
            ) &&
              (!stcpTag || stcpTag === TAG_STCP_TEACHER)) ||
            isNiotAndStcpAdmin,
        },
        {
          id: 'greatTeachingInset',
          label: 'Great Teaching INSET',
          path: '/great-teaching-inset',
          icon: 'Book',
          condition: [ROLE_TEACHER_FREE].includes(userRole),
        },
      ],
    },
    {
      tooltip:
        stcpTag === TAG_STCP_TEACHER ? (
          <>
            These are feedback tools to help teachers embed effective teaching habits through deliberate practice,
            feedback and reflection. <br />
            <u>
              <LinkStyled
                href="https://evidencebased.education/great-teaching-toolkit-cpd/great-teaching-toolkit-school-trust-ceo-programme/"
                target={'_blank'}
              >
                Learn more
              </LinkStyled>
            </u>
          </>
        ) : undefined,
      section: 'My Feedback Tools',
      condition:
        ([ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) &&
          (!stcpTag || stcpTag === TAG_STCP_TEACHER)) ||
        isNiotAndStcpAdmin,
      items: [
        {
          id: 'studentSurveys',
          path: '/student-surveys',
          label: 'Student surveys',
          icon: 'AlignMiddle',
          isolated: stcpTag === TAG_STCP_TEACHER,
          hidden: isFree,
          items: [
            {
              id: 'userFeedback',
              path: '/feedback',
              label: `${isFree ? 'Student f' : 'F'}eedback`,
              icon: isFree ? 'GraphUp' : 'ArrowReturnRight',
              isolated: stcpTag === TAG_STCP_TEACHER,
              isFree: isFree,
            },
          ],
        },
        {
          id: 'videoObservation',
          path: '/video-observation',
          label: 'Video feedback',
          icon: 'Youtube',
          isolated: ['INDUCTION_LEAD'].includes(userTag) || stcpTag === TAG_STCP_TEACHER,
        },
        {
          id: 'schoolEnv',
          label: 'School environment survey',
          path: '',
          icon: 'ListCheck',
          locked: stcpTag != TAG_STCP_TEACHER && userRole !== ROLE_TEACHER_FREE,
          isolated: stcpTag === TAG_STCP_TEACHER,
          condition:
            ([ROLE_TEACHER].includes(userRole) &&
              me!.meta?.early_access_items &&
              !Object.keys(me!.meta?.early_access_items)?.includes('schoolEnvPage')) ||
            stcpTag === TAG_STCP_TEACHER,
        },
      ],
    },
    {
      section: ROLE_MAT_COORDINATOR === userRole ? 'My Schools' : 'My School',
      condition:
        [ROLE_SCHOOL_COORDINATOR, ROLE_MAT_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) ||
        (isFree && me!.data.me.role_type != 'Class teacher'),
      items: [
        {
          id: 'userManagement',
          path: '/user-management',
          label: ROLE_MAT_COORDINATOR === userRole ? 'Coordinators' : 'Teachers',
          icon: 'PeopleFill',
          condition:
            ([ROLE_MAT_COORDINATOR, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) &&
              !me!.data.me.is_induction_lead) ||
            (isFree && me!.data.me.role_type != 'Class teacher'),
        },
        {
          id: 'courses',
          label: 'Courses',
          path: THINKIFIC_LINKS['courses'],
          icon: 'FileSlides',
          condition: [ROLE_MAT_COORDINATOR].includes(userRole),
        },
        {
          id: 'organisationManagement',
          path: '/organisation-management',
          label: 'School planner',
          icon: 'FolderFill',
          condition: [ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) && !me!.data.me.is_ceo,
        },
        {
          id: 'developmentActivities',
          path: '/development-activities',
          label: 'School development activities',
          icon: 'GraphUp',
          condition:
            ([ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) && !me!.data.me.is_ceo) ||
            (isFree && me!.data.me.role_type != 'Class teacher'),
        },
        {
          id: 'schoolFeedback',
          path: '/school-feedback',
          label: 'School feedback',
          icon: 'AlignMiddle',
          isolated: ['INDUCTION_LEAD'].includes(userTag),
          condition: [ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) && !me!.data.me.is_ceo,
        },
        {
          id: 'userFeedback',
          path: '/feedback',
          label: 'School feedback',
          icon: 'AlignMiddle',
          condition: [ROLE_MAT_COORDINATOR].includes(userRole),
        },
        {
          id: 'schoolEnv',
          path: '/school-env',
          label: 'School environment survey',
          icon: 'ListCheck',
          locked: isFree,
          condition:
            ([ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(userRole) && !me!.data.me.is_ceo) ||
            (isFree && me!.data.me.role_type != 'Class teacher'),
        },
      ],
    },
  ];

  const [toggle, setToggle] = useState(false);
  useOnClickOutside(ref, () => setToggle(false));

  const location = useLocation();

  return (
    <AsideContainer
      ref={ref}
      $open={toggle}
      $responsiveWidth={responsiveWidth}
      className={`${VERSIONS[version].background} shadow-lg z-50`}
    >
      <div className={`flex items-center justify-between w-full h-24`}>
        <img
          src={VERSIONS[version].logo}
          alt={'Great Teaching Toolkit'}
          className={`${classnames({
            'hidden md:block': !toggle,
          })} md:m-auto w-3/6 max-w-full h-auto max-h-full p-3`}
        />
        <div className={`${toggle ? 'ml-auto p-2' : 'm-auto'} block md:hidden`}>
          <MenuHamburger handleClick={() => setToggle(!toggle)} toggled={toggle} />
        </div>
      </div>
      <nav className={`${classnames({ 'hidden md:block': !toggle })}`}>
        {NAV_ITEMS.map((section) => {
          if (false === section.condition) {
            return;
          }

          return (
            <React.Fragment key={`section_${section.section}`}>
              <Tooltip content={section.tooltip ?? undefined} disabled={!section.tooltip} placement={'right'}>
                <div key={`nav-section_${section.section}`}>
                  <div className={'block-container font-display uppercase text-primary text-sm'}>{section.section}</div>
                  {section.items.map((item) => {
                    if (false === item.condition) {
                      return;
                    }

                    if (item.id === 'courses' || item.id === 'selfStudy') {
                      return (
                        <React.Fragment key={`nav-section_${section.section}--${item.id}`}>
                          <Tooltip
                            placement={'right'}
                            content={item.tooltip ? item.tooltip : ''}
                            disabled={!item.tooltip}
                          >
                            <LinkActive
                              key={`nav-section_${section.section}--${item.id}`}
                              className={`flex items-center px-2 ${item.isolated ? 'opacity-40 cursor-default' : ''}`}
                              $active={location.pathname === item.path}
                              $version={version}
                              id={item.id === 'courses' ? 'stonly_courses' : undefined}
                              onClick={(e) => {
                                if (item.isolated || item.locked) {
                                  e.preventDefault();
                                }
                              }}
                            >
                              <Icon
                                icon={item.icon as IconType}
                                elementSize={ICON_SIZE}
                                className={`relative z-10 ${VERSIONS[version].text} ${classnames({
                                  'opacity-40': item.locked,
                                })}`}
                              />
                              <a
                                className={`truncate relative z-10 ${item.isolated ? 'cursor-default' : ''} ${
                                  VERSIONS[version].text
                                } block flex-grow pl-2 py-2 ${classnames({
                                  'opacity-40': item.locked,
                                })}`}
                                href={
                                  item.isolated || item.locked
                                    ? '#'
                                    : thinkificSSO.isFetched
                                    ? thinkificSSO.data + item.path
                                    : '#'
                                }
                                target={'_blank'}
                                rel="noreferrer"
                              >
                                {item.label}
                              </a>
                              {item.locked && (
                                <Icon
                                  icon={'Lock'}
                                  elementSize={ICON_SIZE}
                                  className={`relative z-10 ${VERSIONS[version].text} opacity-40`}
                                />
                              )}
                            </LinkActive>
                          </Tooltip>
                        </React.Fragment>
                      );
                    }

                    return (
                      <React.Fragment key={`nav-section_${section.section}--${item.id}`}>
                        <Tooltip
                          placement={'right'}
                          content={
                            item.tooltip
                              ? item.tooltip
                              : item.locked
                              ? `${item.label} is a feature of the paid GTT ${
                                  item.id === 'schoolEnv' ? 'school ' : ''
                                }subscription. Please contact us for more information.`
                              : ''
                          }
                          disabled={!(item.locked || item.tooltip)}
                        >
                          <LinkActive
                            key={`nav-section_${section.section}--${item.id}`}
                            className={`flex items-center px-2 ${item.isolated ? 'opacity-40 cursor-default' : ''} ${
                              item.hidden ? 'hidden' : ''
                            }`}
                            $active={
                              ['pathways', 'greatTeachingTeams', 'videoObservation'].includes(item.id)
                                ? location.pathname.includes(item.path)
                                : location.pathname === item.path
                            }
                            $version={version}
                            id={STONLY_ITEMS_IDS.includes(item.id) ? `stonly_${item.id}` : undefined}
                            onClick={(e) => {
                              if (item.isolated) {
                                e.preventDefault();
                              }
                            }}
                          >
                            <Icon
                              icon={item.icon as IconType}
                              elementSize={ICON_SIZE}
                              className={`relative z-10 ${VERSIONS[version].text} ${classnames({
                                'opacity-40': item.locked,
                              })}`}
                            />
                            <Link
                              to={routeBuilder(item.id).generate(...(item.params ?? []))}
                              data-id={'nav_link'}
                              className={`${item.isolated ? 'cursor-default' : ''} ${
                                item.id === 'schoolEnv' ? '' : 'truncate'
                              } relative z-10 ${VERSIONS[version].text} block flex-grow pl-2 py-2 ${classnames({
                                'opacity-40': item.locked,
                              })}`}
                              onClick={(e) => {
                                if (item.locked || item.isolated) {
                                  e.preventDefault();
                                }
                              }}
                            >
                              {item.label}
                            </Link>
                            {item.locked && (
                              <Icon
                                icon={'Lock'}
                                elementSize={ICON_SIZE}
                                className={`relative z-10 ${VERSIONS[version].text} opacity-40`}
                              />
                            )}
                          </LinkActive>
                        </Tooltip>

                        {null != item.items && 0 !== item.items.length
                          ? item.items.map((subitem, index: number) => {
                              if (subitem.path != FEEDBACK_PATH) {
                                // If the parent isn't selected, the child isn't shown
                                if (location.pathname !== item.path) {
                                  return;
                                }

                                if (false === subitem.condition) {
                                  return;
                                }
                              }

                              return (
                                <Tooltip
                                  key={index}
                                  placement={'right'}
                                  content={
                                    subitem.tooltip
                                      ? subitem.tooltip
                                      : subitem.locked
                                      ? `${subitem.label} is a feature of the paid GTT ${
                                          subitem.id === 'schoolEnv' ? 'school ' : ''
                                        }subscription. Please contact us for more information.`
                                      : ''
                                  }
                                  disabled={!(subitem.locked || subitem.tooltip)}
                                >
                                  <LinkActive
                                    key={`nav-section_${section.section}--${subitem.id}`}
                                    className={`flex items-center px-2 ${
                                      subitem.isolated ? 'opacity-40 cursor-default' : ''
                                    }`}
                                    $active={location.pathname === subitem.path}
                                    $version={version}
                                    $isChild={!isFree || (isFree && subitem.id != 'userFeedback')}
                                    $feedback={subitem.path === FEEDBACK_PATH}
                                    onClick={(e) => {
                                      if (subitem.isolated || subitem.locked) {
                                        e.preventDefault();
                                      }
                                    }}
                                  >
                                    <Icon
                                      icon={subitem.icon as IconType}
                                      elementSize={ICON_SIZE}
                                      className={`relative z-10 ${VERSIONS[version].text} ${classnames({
                                        'opacity-40': subitem.locked,
                                      })}`}
                                    />
                                    <Link
                                      to={routeBuilder(subitem.id as PathType).generate()}
                                      data-id={'nav_link'}
                                      onClick={(e) => {
                                        if (subitem.isolated || subitem.locked) {
                                          e.preventDefault();
                                        }
                                      }}
                                      className={`truncate relative z-10 ${subitem.isolated ? 'cursor-default' : ''} ${
                                        VERSIONS[version].text
                                      } block flex-grow pl-2 py-2 ${classnames({
                                        'opacity-40': subitem.locked,
                                      })}`}
                                    >
                                      {subitem.label}
                                    </Link>
                                    {subitem.locked && (
                                      <Icon
                                        icon={'Lock'}
                                        elementSize={ICON_SIZE}
                                        className={`relative z-10 ${VERSIONS[version].text} opacity-40`}
                                      />
                                    )}
                                  </LinkActive>
                                </Tooltip>
                              );
                            })
                          : null}
                      </React.Fragment>
                    );
                  })}
                </div>
              </Tooltip>
            </React.Fragment>
          );
        })}
        {me!.meta?.early_access_items && (
          <div key={`nav-section`}>
            <div className={'block-container font-display uppercase text-primary text-sm'}>Early access</div>
            {Object.entries<{ path: string; label: string }>(me!.meta.early_access_items).map((item) => (
              <LinkActive
                key={`early_access_${item[0]}`}
                className={'flex items-center px-2'}
                $active={location.pathname === item[1]['path']}
                $version={version}
                id={
                  STONLY_ITEMS_IDS.includes(item[0])
                    ? item[0] === 'schoolEnvPage'
                      ? 'stonly_schoolEnv'
                      : `stonly_${item[0]}`
                    : undefined
                }
              >
                <Icon
                  icon={'ExclamationCircle'}
                  elementSize={ICON_SIZE}
                  className={`relative z-10 ${VERSIONS[version].text}`}
                />
                <Link
                  to={item[1]['path']}
                  data-id={'nav_link'}
                  className={`${item[1].path === '/school-env' ? '' : 'truncate'} relative z-10 ${
                    VERSIONS[version].text
                  } block flex-grow pl-2 py-2`}
                >
                  {item[1]['label']}
                </Link>
              </LinkActive>
            ))}
          </div>
        )}
      </nav>
    </AsideContainer>
  );
};

const AsideContainer = styled('aside')<{ $open: boolean; $responsiveWidth: number; $dark?: boolean }>`
  width: ${(props) => (props.$open ? '85%' : `${props.$responsiveWidth}px`)};

  @media (min-width: 425px) {
    width: ${(props) => (props.$open ? '45%' : `${props.$responsiveWidth}px`)};
  }

  height: 100%;
  position: fixed;
  overflow: hidden;
  transition: width ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_TRANSITION_FUNC};

  @media (min-width: 768px) {
    position: inherit;
    width: 25%;
  }
  @media (min-width: 1440px) {
    width: 15%;
  }
`;

const LinkActive = styled('div')<{
  $active: boolean;
  $version: 'dark' | 'light';
  $isChild?: boolean;
  $feedback?: boolean;
}>`
  position: relative;
  z-index: 1;

  &:hover {
    background: ${(props) => transparentize('light' === props.$version ? 0.5 : 0.85, props.theme.colors.light)};
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    background: ${(props) => transparentize('light' === props.$version ? 0.5 : 0.85, props.theme.colors.light)};
    ${(props) =>
      props.$active &&
      `animation: bg_active ${DEFAULT_TRANSITION_FUNC} ${DEFAULT_TRANSITION_DURATION}ms forwards;`} @keyframes bg_active {
      0% {
        width: 0;
      }

      100% {
        width: 100%;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    height: 0;
    width: 3px;
    background: ${(props) => props.theme.colors.primary};
    ${(props) =>
      props.$active &&
      `animation: item_active ${DEFAULT_TRANSITION_FUNC} ${DEFAULT_TRANSITION_DURATION}ms forwards;`} @keyframes item_active {
      0% {
        height: 0;
      }

      100% {
        height: 100%;
      }
    }
  }

  ${(props) =>
    props.$isChild &&
    `
    padding-left: ${ICON_SIZE}px;
    font-style: italic;
    opacity: 0.75;
    background: ${
      !props.$feedback && transparentize('light' === props.$version ? 0.6 : 0.95, props.theme.colors.light)
    };
    &:before {
      ${!props.$feedback && 'background: transparent;'}
      top: 0;
      bottom: 0;
      margin: auto;
    }
  `}
`;

AsideContent.displayName = 'AsideContent';

export default AsideContent;
