import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import Section from '../../common/components/section';
import { useQuery } from 'react-query';
import { CALENDAR_MEETINGS_AND_EVENTS, MEETINGS_AND_EVENTS } from '../../../service/queryKeys';
import { getMeetingsByDate } from '../../../service/api/groupsApi';
import { getSharedEvents } from '../../../service/api/eventsApi';
import { Meeting } from '../../../@types/Entity/Meeting';
import { Event } from '../../../@types/Entity/Event';
import { MeetingPrerequisite } from '../../../@types/Entity/MeetingPrerequisite';
import Icon from '../../../global/icon/icon';
import { convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

type MeetingAndEvent = {
  id: string;
  scheduledAt: string;
  title: string;
  location: string;
  prerequisites?: MeetingPrerequisite[];
  date?: string;
  startTime?: string;
  endTime?: string;
  description?: string;
};

export default function MeetingsOverview({ title }: { title?: string }) {
  const [selectedMeetingEvents, setSelectedMeetingEvents] = useState<{
    selectedDate: string | null; //Only used to change selected date styles in the calendar
    meetingsEvents: MeetingAndEvent[];
  }>({ selectedDate: null, meetingsEvents: [] });

  const today = dayjs();
  const startOfCurrentWeek = today.startOf('week').subtract(1, 'day').startOf('day');
  const endOfNextWeek = startOfCurrentWeek.add(14, 'day').endOf('day');
  const [calendarSelectedDate, setCalendarSelectedDate] = useState(dayjs().startOf('month'));

  const meetingsAndEventsQuery = useQuery([MEETINGS_AND_EVENTS], () =>
    Promise.all([
      getMeetingsByDate(today.startOf('day'), endOfNextWeek.endOf('day')),
      getSharedEvents(today.startOf('day').format('YYYY/MM/DD'), endOfNextWeek.endOf('day').format('YYYY/MM/DD')),
    ])
  );

  const calendarMeetingsAndEventsQuery = useQuery(
    [CALENDAR_MEETINGS_AND_EVENTS, { month: calendarSelectedDate.format('YYYY/MM') }],
    () =>
      Promise.all([
        getMeetingsByDate(calendarSelectedDate, calendarSelectedDate.endOf('month')),
        getSharedEvents(
          calendarSelectedDate.format('YYYY/MM/DD'),
          calendarSelectedDate.endOf('month').format('YYYY/MM/DD')
        ),
      ])
  );

  const calendarMeetingsAndEvents =
    calendarMeetingsAndEventsQuery.data?.reduce<MeetingAndEvent[]>((acc, curr, index) => {
      if (index === 0) {
        const data = (curr.data.data as Meeting[]).map((meeting) => ({
          id: meeting.id,
          scheduledAt: meeting.scheduledAt,
          title: meeting.title,
          location: meeting.location,
          prerequisites: meeting.prerequisites,
        }));

        return [...acc, ...data];
      }

      const data = (curr.data.data as Event[]).map((event) => ({
        id: event.id,
        // Used to compare it with the meetings
        scheduledAt: dayjs(`${event.date} ${event.start_time}`, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm'),
        date: event.date,
        startTime: event.start_time,
        endTime: event.end_time,
        title: event.title,
        location: event.location,
        description: event.description,
      }));

      return [...acc, ...data];
    }, []) ?? [];

  const meetingAndEvents =
    meetingsAndEventsQuery.data
      ?.reduce<MeetingAndEvent[]>((acc, curr, index) => {
        if (index === 0) {
          const data = (curr.data.data as Meeting[]).map((meeting) => ({
            id: meeting.id,
            scheduledAt: meeting.scheduledAt,
            title: meeting.title,
            location: meeting.location,
            prerequisites: meeting.prerequisites,
          }));

          return [...acc, ...data];
        }

        const data = (curr.data.data as Event[]).map((event) => ({
          id: event.id,
          // Used to compare it with the meetings
          scheduledAt: dayjs(`${event.date} ${event.start_time}`, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm'),
          date: event.date,
          startTime: event.start_time,
          endTime: event.end_time,
          title: event.title,
          location: event.location,
          description: event.description,
        }));

        return [...acc, ...data];
      }, [])
      ?.sort((a, b) => new Date(a.scheduledAt).getTime() - new Date(b.scheduledAt).getTime()) ?? [];

  let lastPrintedDay: string | null = null;

  return (
    <>
      {title && <h2>{title}</h2>}

      <Section size={'md'} className={'mt-5'}>
        <div className={'xl:grid xl:grid-cols-3 xl:gap-4'}>
          <div>
            <Calendar
              calendarType={'US'}
              tileClassName={({ date, view }) => {
                if ('month' !== view) {
                  return null;
                }

                const calendarDay = dayjs(date);

                const classNames = [];

                if (selectedMeetingEvents.selectedDate === calendarDay.format('YYYY/MM/DD')) {
                  classNames.push('text-warning font-bold');
                }

                if (
                  calendarMeetingsAndEvents.some(
                    (meetingEvent) =>
                      dayjs(meetingEvent.scheduledAt).format('YYYY/MM/DD') === calendarDay.format('YYYY/MM/DD')
                  )
                ) {
                  classNames.push('bg-primary');
                } else if (calendarDay > startOfCurrentWeek && calendarDay < endOfNextWeek) {
                  classNames.push('bg-primary', 'bg-opacity-50');
                } else {
                  classNames.push('bg-white');
                }

                if (calendarDay.format('YYYY/MM/DD') === today.format('YYYY/MM/DD')) {
                  classNames.push('font-bold');
                }

                return classNames;
              }}
              onActiveStartDateChange={({ activeStartDate }) => setCalendarSelectedDate(dayjs(activeStartDate!))}
              onClickDay={(value) => {
                const date = dayjs(value).format('YYYY/MM/DD');

                setSelectedMeetingEvents({
                  selectedDate: date,
                  meetingsEvents: calendarMeetingsAndEvents.filter(
                    (meetingAndEvents: MeetingAndEvent) =>
                      dayjs(meetingAndEvents.scheduledAt).format('YYYY/MM/DD') === date
                  ),
                });
              }}
            />
          </div>

          <div className={'mt-10 xl:mt-0'}>
            <h5>Upcoming</h5>
            <div className={'mt-2'}>
              <div className={'grid grid-cols-12'}>
                {0 === (meetingAndEvents.length ?? 0) && (
                  <div className={'col-span-12'}>There are no meetings/events.</div>
                )}
                {meetingAndEvents.map((meetingEvent) => {
                  const meetingDate = dayjs(meetingEvent.scheduledAt);
                  const meetingFormattedDate = dayjs(meetingEvent.scheduledAt).format('YYYY-MM-DD');

                  const component = (
                    <React.Fragment key={meetingEvent.id}>
                      <div className={'col-span-2 mt-2 mr-2 text-center'}>
                        {meetingFormattedDate !== lastPrintedDay && (
                          <>
                            <p className={'text-sm'}>{meetingDate.format('ddd')}</p>
                            <p className={'text-2xl font-bold'}>{meetingDate.format('DD')}</p>
                          </>
                        )}
                      </div>

                      <div className={'col-span-10 mt-2'}>
                        <p
                          className={'cursor-pointer'}
                          onClick={() =>
                            setSelectedMeetingEvents({
                              selectedDate: null,
                              meetingsEvents: [meetingEvent],
                            })
                          }
                        >
                          {meetingEvent.title}
                        </p>
                        <p className={'text-sm'}>
                          {dayjs(meetingEvent.scheduledAt).format('HH:mm')}
                          {meetingEvent.endTime ? ` - ${meetingEvent.endTime}` : ''} ・ {meetingEvent.location}
                        </p>
                      </div>
                    </React.Fragment>
                  );

                  lastPrintedDay = meetingFormattedDate;

                  return component;
                })}
              </div>
            </div>
          </div>

          {(selectedMeetingEvents?.meetingsEvents?.length ?? 0) > 0 && (
            <div className={'mt-6 xl:mt-0'}>
              {selectedMeetingEvents?.meetingsEvents?.map((selectedMeetingEvent, index) => (
                <div key={selectedMeetingEvent.id} className={'border-2 border-gray-200 rounded p-2 mb-2'}>
                  <div className={'flex justify-between'}>
                    <p>{selectedMeetingEvent.title}</p>
                    <Icon
                      tooltipText={'Close'}
                      onClick={() =>
                        setSelectedMeetingEvents((state) => ({
                          ...state,
                          meetingsEvents: state.meetingsEvents.filter((item) => item.id !== selectedMeetingEvent.id),
                        }))
                      }
                      icon={'XSquare'}
                      color={'grey'}
                      elementSize={20}
                      className={'cursor-pointer'}
                      style={{ padding: 0, margin: 0 }}
                    />
                  </div>
                  <p className={'text-sm'}>
                    {dayjs(selectedMeetingEvent.scheduledAt).format('HH:mm')}
                    {selectedMeetingEvent.endTime ? ` - ${selectedMeetingEvent.endTime}` : ''} ・{' '}
                    {selectedMeetingEvent.location}
                  </p>
                  {selectedMeetingEvent.description !== undefined ? (
                    <Editor
                      readOnly={true}
                      toolbarHidden={true}
                      editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(selectedMeetingEvent.description)))}
                    />
                  ) : (
                    <ul className={'list-disc list-inside'}>
                      {selectedMeetingEvent.prerequisites?.map((prerequisite) => (
                        <li key={prerequisite.id} className={'text-sm'}>
                          {prerequisite.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </Section>
    </>
  );
}
