import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { DASHBOARD } from '../../service/queryKeys';
import { getDashboard } from '../../service/api';
import DashboardLayout from '../../layout/dashboard';
import { MatDashboard, SchoolDashboard, TeacherDashboard } from '../dashboard/views';
import {
  ROLE_ASSISTANT_GT_COORDINATOR,
  ROLE_MAT_COORDINATOR,
  ROLE_SCHOOL_COORDINATOR,
  ROLE_TEACHER,
  ROLE_TEACHER_FREE,
  TAG_ASSOCIATE_COLLEGE,
  TAG_ECT,
  TAG_INDUCTION_LEAD,
  TAG_MENTOR,
  TAG_NIOT,
  TAG_REGIONAL_LEAD,
} from '../../resources/roles';
import dayjs from 'dayjs';
import { Me } from '../../@types/Entity/Me';
import NiotDashboard from '../dashboard/views/niotDashboard';
import NiotCeoDashboard from '../dashboard/views/niotCeoDashboard';

const HomePage = ({ me }: { me: Me }) => {
  const isNiot =
    me.is_early_career_teacher ||
    me.mentor ||
    me.is_induction_lead ||
    me.is_associate_college ||
    me.is_regional_lead ||
    me.is_niot;

  const { data: dashboardData, isFetched } = useQuery(DASHBOARD, () => getDashboard(), {
    select: (data) => data.data.data,
    placeholderData: {},
    staleTime: Infinity,
    enabled:
      !isNiot &&
      [ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role),
  });

  const GREETING = () => {
    const date = new Date();

    const time = date.getHours();

    if (time < 12) {
      return 'morning';
    } else if (time < 18) {
      return 'afternoon';
    } else {
      return 'evening';
    }
  };

  const isFree = me?.role === ROLE_TEACHER_FREE;

  return (
    <DashboardLayout
      title={
        isFree ? `Welcome to your GTT: Starter Account, ${me?.first_name}!` : `Good ${GREETING()}, ${me?.first_name}!`
      }
      subtitle={dayjs(new Date()).format('dddd DD MMMM YYYY')}
    >
      {((me.is_ceo_admin && me.is_niot) || isNiot) && <NiotDashboard me={me} />}
      {me.is_ceo && !isNiot && <NiotCeoDashboard me={me} />}
      {!me.is_ceo && !isNiot && isFetched && [ROLE_TEACHER, ROLE_TEACHER_FREE].includes(me.role) && (
        <TeacherDashboard
          data={dashboardData}
          isFree={me.role === ROLE_TEACHER_FREE}
          hidePlan={me.role === ROLE_TEACHER_FREE || !me.school_user_id}
          name={me.first_name + ' ' + me.last_name}
          email={me.email}
          me={me}
        />
      )}
      {!me.is_ceo &&
        !isNiot &&
        isFetched &&
        [ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role) && (
          <SchoolDashboard data={dashboardData} name={me.first_name + ' ' + me.last_name} email={me.email} />
        )}
      {!isNiot && me.role === ROLE_MAT_COORDINATOR && <MatDashboard />}
    </DashboardLayout>
  );
};

export default HomePage;
