import React from 'react';
import Button from '../../../global/button/button';
import { NO_LINK, useRegistrationContext } from '../../../state/registration/provider';
import { ROLE_MAT_COORDINATOR, ROLE_SCHOOL_COORDINATOR } from '../../../resources/roles';

const Step5 = () => {
  const { step1, step2, link, step3 } = useRegistrationContext();

  return (
    <>
      {step3?.start_date ? (
        <>
          Thank you for registering for the Great Teaching Toolkit! We&apos;ll contact you on your subscription start
          date with your invoice, and you will be able to log in using your email and password from that date.
        </>
      ) : (
        <>
          {link !== NO_LINK && step1?.role === ROLE_MAT_COORDINATOR && (
            <>
              <p>Thank you for registering, please share this link with the school lead: </p>
              <b>{link}</b>
              <p>
                They will need to register their school using this link so that their school is registered correctly
              </p>
              <br />
            </>
          )}
          {step1?.role === ROLE_SCHOOL_COORDINATOR && (
            <>
              <p>
                We&apos;re delighted that {step2?.organisation} has joined the Great Teaching Toolkit! Your account has
                now been successfully created. Click below to login, get started and share access with your team.
              </p>
              <br />
            </>
          )}

          <p>Your account has been successfully created! Click below to login and get started.</p>
        </>
      )}

      <Button
        className="w-full mt-5"
        onClick={() => {
          if (step3?.start_date) {
            window.location.href = 'https://evidencebased.education/';
            return;
          }

          window.location.href = '/login';
        }}
      >
        {step3?.start_date ? 'Return to EBE' : 'Go to Login'}
      </Button>
    </>
  );
};

export default Step5;
