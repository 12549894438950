import React, { ReactNode } from 'react';
import FooterContent from '../pages/common/footer';
import GTT_LOGO from '@app/asset/images/Great_Teaching_Toolkit_Logo_Dark.png';
import GTT_NIOT_LOGO from '@app/asset/images/GTT_NIoT.png';

interface AuthLayoutProps {
  /**
   * Page title.
   */
  title: string;
  /**
   * Page description.
   */
  description?: ReactNode | null;
  /**
   * Page content.
   */
  children: any;
  /**
   * Different image for NioT users.
   */
  niotMode?: boolean;

  site?: any;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ title, description, children, niotMode = false }: AuthLayoutProps) => {
  return (
    <div className={'flex flex-col h-full bg-opacity-50'}>
      <div className={'md:w-3/5 max-w-screen-md py-10 m-auto'}>
        <header className={'mb-5'}>
          <div className={`${niotMode ? 'w-3/4' : 'w-4/12'} mx-auto`}>
            <img
              src={niotMode ? GTT_NIOT_LOGO : GTT_LOGO}
              alt={'Great Teaching Toolkit'}
              width={'100%'}
              height={'auto'}
            />
          </div>
        </header>
        <section className={'shadow'}>
          <main className={'block-container bg-white'}>
            {title && (
              <div className={'border-b pb-5 mb-5'}>
                <h2>{title}</h2>
                {description && <div className={'mt-3'}>{description}</div>}
              </div>
            )}
            {children as any}
          </main>
          <footer className={'bg-secondary'}>
            <FooterContent showLogoAtCopyright={false} />
          </footer>
        </section>
      </div>
    </div>
  );
};

export default AuthLayout;
