import connector from '../connector';
import { ApiResponse } from '../../@types/global';
import { Meeting } from '../../@types/Entity/Meeting';
import { Dayjs } from 'dayjs';
import { MeetingPathway } from '../../@types/Entity/MeetingPathway';
import { MeetingTemplate } from '../../@types/Entity/MeetingTemplate';
import { ActionTag } from '../../@types/Entity/ActionTag';
import { User } from '../../@types/Entity/User';

export function postOrPutGroup(data: PostOrPutGroupData, isMentee = false, autoAddCreator = false) {
  if (data.id) {
    return connector.put('/api/group', data);
  }

  const params: Record<string, any> = {};

  if (isMentee) {
    params.isMentee = '';
  }

  if (autoAddCreator) {
    params.autoAddCreator = '';
  }

  return connector.post('/api/group', data, { params: params });
}

export function deleteGroup(groupId: string) {
  return connector.delete(`/api/group/${groupId}`);
}


export function reassignGroup({ groupId, mentors }: {groupId: string; mentors: string[]}) {
  return connector.put(`/api/group/${groupId}/reassign`, { mentors: mentors });
}

export function postMeetingsUpdateIsArchived(data: PostMeetingsUpdateIsArchivedData) {
  return connector.post('/api/meetings/update-is-archived', data);
}

export function postMeetingUpdateAgenda(id: string, data: PostMeetingUpdateAgendaData) {
  const formData = new FormData();

  if (data.agendaItems) {
    formData.append('agendaItems', JSON.stringify(data.agendaItems));
  }

  if (data.prerequisites) {
    formData.append('prerequisites', JSON.stringify(data.prerequisites));
  }

  if (data.resources) {
    formData.append('resources', JSON.stringify(data.resources));
  }

  if (data.files) {
    for (const file of data.files ?? []) {
      formData.append(`files[]`, file);
    }
  }

  if (data.meetingPurpose) {
    formData.append('purpose', data.meetingPurpose);
  }

  if (data.type) {
    formData.append('type', data.type);
  }

  if (data.use_template) {
    formData.append('use_template', data.use_template.toString());
  }

  if (data.template_id) {
    formData.append('template_id', data.template_id);
  }

  return connector.post(`/api/meetings/${id}/update-agenda`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function getMeetingsByDate(startDate: Dayjs, endDate: Dayjs) {
  return connector.get<ApiResponse<Meeting[]>>('/api/meetings', {
    params: {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    },
  });
}

export function getMeetingsByGroup(group: string) {
  return connector.get<ApiResponse<Meeting[]>>(`/api/groups/${group}/meetings`).then((response) => response.data);
}

export function getMeetingsPathways() {
  return connector.get<ApiResponse<MeetingPathway[]>>('/api/meeting-pathways').then((response) => response.data);
}

export function postCreateMeetingPathway(data: CreateUpdateMeetingPathwayRequestBody) {
  return connector.post('/api/meeting-pathways', data).then(() => {
    /* Do nothing */
  });
}

export function putUpdateMeetingPathway(id: string, data: CreateUpdateMeetingPathwayRequestBody) {
  return connector.post(`/api/meeting-pathways/${id}`, data).then(() => {
    /* Do nothing */
  });
}

export function getMeetingTemplates(query?: GetMeetingTemplatesQuery) {
  return connector
    .get<ApiResponse<MeetingTemplate[]>>('/api/meeting-templates', { params: query })
    .then((response) => response.data);
}

export function postCreateMeetingTemplate(data: CreateUpdateMeetingTemplateRequestBody) {
  return connector.post('/api/meeting-templates', data).then(() => {
    /* Do nothing */
  });
}

export function putUpdateMeetingTemplate(id: string, data: CreateUpdateMeetingTemplateRequestBody) {
  return connector.post(`/api/meeting-templates/${id}`, data).then(() => {
    /* Do nothing */
  });
}

export function getMeeting(id: string): Promise<ApiResponse<Meeting>> {
  return connector.get(`/api/meetings/${id}`).then((response) => response.data);
}

export function startOrBlockMeeting(id: string, startOrBlock: 'start' | 'block') {
  return connector.post(`/api/meetings/${id}/${startOrBlock}`).then(() => {
    /* Do nothing */
  });
}

export function updateMeeting(id: string, data: UpdateMeetingRequestBody, filesToAdd?: File[] | undefined) {
  const filesFormData = new FormData();

  if (filesToAdd) {
    for (const file of filesToAdd) {
      filesFormData.append(`files[]`, file);
    }

    return connector
      .post(`/api/meetings/${id}/add-files`, filesFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(() =>
        connector.put(`/api/meetings/${id}`, data).then(() => {
          /* Do nothing */
        })
      );
  } else
    return connector.put(`/api/meetings/${id}`, data).then(() => {
      /* Do nothing */
    });
}

export function getGroupMeetingsActionsTags(groupId: string) {
  return connector
    .get<ApiResponse<ActionTag[]>>(`/api/groups/${groupId}/meetings-actions-tags`)
    .then((response) => response.data);
}

export function getGroupMembers(groupId: string) {
  return connector.get<ApiResponse<User[]>>(`/api/groups/${groupId}/members`).then((response) => response.data);
}

export function checkMeetingTemplateTitleExists(title: string, idToIgnore: string | null) {
  return connector
    .get<ApiResponse<{ exists: boolean }>>(`/api/meeting-templates/check-title`, {
      params: { title: title, idToIgnore: idToIgnore },
    })
    .then((response) => response.data);
}

export function checkMeetingPathwayTitleExists(title: string, idToIgnore: string | null) {
  return connector
    .get<ApiResponse<{ exists: boolean }>>(`/api/meeting-pathways/check-title`, {
      params: { title: title, idToIgnore: idToIgnore },
    })
    .then((response) => response.data);
}

export function getDownloadMeetingFile(id: string, name: string) {
  return connector.get(`/api/meetings-files/${id}`, { responseType: 'blob' }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
}

export type PostOrPutGroupData =
  | {
      id?: never;
      name: string;
      members: string[];
      focusedElement?: string | null;
      meetingPathway?: string | null;
      cohort: boolean;
      meetings?: {
        id?: string | null;
        title: string;
        scheduledAt: string;
        location: string;
      }[];
      enabled?: never;
    }
  | {
      id: string;
      name?: string;
      members?: string[];
      focusedElement?: string | null;
      meetingPathway?: string | null;
      cohort?: boolean;
      meetings?: {
        id?: string | null;
        title: string;
        scheduledAt: string;
        location: string;
      }[];
      enabled?: boolean;
    };

export interface PostMeetingsUpdateIsArchivedData {
  ids: string[];
  isArchived: boolean;
}

export interface PostMeetingUpdateAgendaData {
  prerequisites: {
    id?: string | null;
    title: string;
    index: number;
  }[];
  agendaItems: {
    id?: string | null;
    title: string;
    index: number;
  }[];
  resources?: {
    id?: string | null;
    title?: string;
    url?: string;
    index: number;
  }[];
  files?: File[];
  meetingPurpose?: string;
  type?: string;
  use_template?: boolean;
  template_id?: string;
}

export interface GetMeetingTemplatesQuery {
  meetingPathway?: 'without' | string;
}

export interface UpdateMeetingRequestBody {
  agendaItems: {
    id: string;
    isChecked: boolean;
  }[];
  praiseStrength: string | null;
  probeAreas: string | null;
  filesToBeRemoved: string[];
  feedbacks: {
    id: string | null;
    instrumentSchoolClass: string | null;
    video: string | null;
    element: string | null;
  }[];
  actions: {
    id: string | null;
    title: string;
    tags: {
      id: string | null;
      title: string;
    }[];
    assignedTo: string;
    dueDate: string;
  }[];
  isFinishing?: boolean;
}

export interface CreateUpdateMeetingTemplateRequestBody {
  title: string;
  prerequisites: {
    title: string;
  }[];
  agendaItems: {
    title: string;
  }[];
  resources: {
    title: string;
    url: string;
  }[];
}

export interface CreateUpdateMeetingPathwayRequestBody {
  title: string;
  meetingTemplates: {
    id: string;
  }[];
}
