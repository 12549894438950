import React from 'react';
import { FieldProps } from './field.types';
import { capitalize, getAriaDescribedby } from '../../helpers/misc';
import Icon from '../../global/icon/icon';

const ErrorFeedback: React.FC<Partial<FieldProps>> = ({ id, error }: Partial<FieldProps>) => {
  return (
    <div id={getAriaDescribedby(id as string)} className={'flex items-center gap-2 text-danger mt-3'}>
      <Icon icon={'ExclamationCircle'} elementSize={20} container={false} />
      <p>{capitalize(error || '')}</p>
    </div>
  );
};

ErrorFeedback.displayName = 'ErrorFeedback';

export default ErrorFeedback;
