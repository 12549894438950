import React from 'react';
import { Section } from '../../common';
import { AddButton } from '../../../global/button/common';
import DefaultTable from '../../../global/table/defaultTable';
import { ActionButton } from '../../../global/buttonIcon/common';
import { useMutation, useQueryClient } from 'react-query';
import { postOrPutAcademicYear } from '../../../service/api';
import dayjs from 'dayjs';
import useModal from '../../../hooks/useModal';
import AcademicYearModal from '../modals/academicYearModal';
import { ACADEMIC_YEARS } from '../../../service/queryKeys';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { AcademicYear } from '../../../@types/Entity/AcademicYear';
dayjs.extend(customParseFormat);

const PathwaysAcademicYears = ({ data }: { data: AcademicYear[] }) => {
  const queryClient = useQueryClient();

  const [academicYearModal, toggleAcademicYearModal, setAcademicYearProps] = useModal((props: any) => (
    <AcademicYearModal {...props} />
  ));

  const mutation = useMutation(postOrPutAcademicYear, {
    onSuccess: () => {
      queryClient.invalidateQueries(ACADEMIC_YEARS);
      toggleAcademicYearModal(false);
    },
  });

  return (
    <>
      {academicYearModal}
      <Section
        id="academic-years"
        headline={'Academic years'}
        more={0 < data.length ? `${data.length} academic year${1 !== data.length ? 's' : ''}` : ''}
        className={'field-mb'}
      >
        <DefaultTable
          id="academic-years"
          showCounter={false}
          columns={[
            { id: 'name', label: 'Name' },
            { id: 'start_date', label: 'Start date' },
            { id: 'end_date', label: 'End date' },
            { id: 'actions', label: 'Actions' },
          ]}
          rows={
            0 === data?.length
              ? []
              : data.map((academicYear, index) => {
                  return {
                    id: `academic_year_row--${index}`,
                    cells: [
                      { id: 'name', content: academicYear.name },
                      { id: 'start_date', content: academicYear.startDate },
                      { id: 'end_date', content: academicYear.endDate },
                      {
                        id: 'actions',
                        class: 'actions',
                        content: (
                          <ul className={'actions-list'}>
                            <li>
                              <ActionButton.Edit
                                archived={false}
                                onClick={() => {
                                  setAcademicYearProps({
                                    academicYear: {
                                      ...academicYear,
                                      start_date: dayjs(academicYear.startDate, 'DD/MM/YYYY').toDate(),
                                      end_date: dayjs(academicYear.endDate, 'DD/MM/YYYY').toDate(),
                                    },
                                    mutation,
                                    toggle: toggleAcademicYearModal,
                                    title: 'Update academic year',
                                  });

                                  toggleAcademicYearModal(true);
                                }}
                              />
                            </li>
                          </ul>
                        ),
                      },
                    ],
                  };
                })
          }
          noDataMessage={'Start adding Terms clicking on "Add academic year" button.'}
        />
        <div className={'flex justify-end mt-8'}>
          <AddButton
            id={'stonly_add_academic_year'}
            key={'add-academicYear-button'}
            onClick={() => {
              setAcademicYearProps({
                mutation,
                toggle: toggleAcademicYearModal,
                title: 'Add academic year',
              });

              toggleAcademicYearModal(true);
            }}
            label={['Academic', <br key={0} />, 'Year']}
          />
        </div>
      </Section>
    </>
  );
};

export default PathwaysAcademicYears;
