import Modal from '../../../../global/messages/modal/modal';
import ModalComponents from '../../../../global/messages/modal/modal.components';
import React from 'react';
import { ModalProps } from '../../../../global/messages/modal/modal.types';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Input from '../../../../form/input/input';
import { MeetingFormValues } from '../MeetingItem';
import { AddButton } from '../../../../global/button/common';
import Datepicker from '../../../../form/datepicker/datepicker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Selector from '../../../../form/select/selector';
import ButtonIcon from '../../../../global/buttonIcon/buttonIcon';
import { ApiResponse, Option } from '../../../../@types/global';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from 'react-query';
import { getGroupMeetingsActionsTags, getGroupMembers } from '../../../../service/api/groupsApi';
import { GROUP_MEETINGS_ACTIONS_TAGS, GROUP_MEMBERS } from '../../../../service/queryKeys';
import { User } from '../../../../@types/Entity/User';

dayjs.extend(customParseFormat);

const VALIDATION_SCHEMA = Yup.object().shape({
  actions: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().nullable().required('This field is required'),
      tags: Yup.array(),
      assignedTo: Yup.object().nullable().required('This field is required'),
      dueDate: Yup.string().nullable().required('This field is required'),
    })
  ),
});

export default function EditActionsModal({ group, actions, toggle, onSubmit }: Props) {
  const getGroupMeetingsTagsQuery = useQuery([GROUP_MEETINGS_ACTIONS_TAGS, { id: group }], () =>
    getGroupMeetingsActionsTags(group)
  );

  const getGroupMembersQuery = useQuery<ApiResponse<User[]>>(
    [GROUP_MEMBERS, { id: group }],
    () => getGroupMembers(group),
    {
      placeholderData: {
        data: [],
      },
    }
  );

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Pick<MeetingFormValues, 'actions'>>({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: {
      actions: actions,
    },
  });

  const actionsFieldArray = useFieldArray({
    control: control,
    name: 'actions',
    keyName: 'uniqueKey',
  });

  return (
    <Modal open toggle={() => toggle()} title={'Actions'} size={'md'}>
      <form onSubmit={handleSubmit((values) => onSubmit(values.actions))}>
        <ModalComponents.Body>
          <div className={'flex justify-end mb-2'}>
            <AddButton
              label={'Add action'}
              isOutline
              mainColor={'muted'}
              size={'xsm'}
              onClick={() =>
                actionsFieldArray.append({ id: null, title: '', tags: [], assignedTo: null, dueDate: null })
              }
            />
          </div>
          {actionsFieldArray.fields.map((field, index) => (
            <div key={field.uniqueKey} className={'mb-4 flex'}>
              <div className={'flex-1'}>
                <div className={'flex'}>
                  <div className={'flex-1 border p-2'}>
                    <Input
                      {...register(`actions.${index}.title` as const)}
                      id={`action_${field.id}_title`}
                      placeholder={'Action'}
                      error={errors.actions?.[index]?.title?.message}
                    />
                  </div>
                  <div className={'flex-1 border p-2'}>
                    <Controller
                      control={control}
                      name={`actions.${index}.tags` as const}
                      render={({ field: { onChange, value } }) => (
                        <Selector
                          id={`action_${field.id}_tags`}
                          placeholder={'Tags'}
                          isMulti
                          isCreatableSelect
                          options={
                            getGroupMeetingsTagsQuery.data?.data.map((tag) => ({ label: tag.title, value: tag.id })) ??
                            []
                          }
                          onChange={(value: Option[]) => onChange(value)}
                          value={value}
                          error={errors.actions?.[index]?.tags?.message}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className={'flex'}>
                  <div className={'flex-1 border p-2'}>
                    <Controller
                      control={control}
                      name={`actions.${index}.assignedTo` as const}
                      render={({ field: { onChange, value } }) => (
                        <Selector
                          id={`action_${field.id}_assignedTo`}
                          placeholder={'Assigned to'}
                          options={
                            getGroupMembersQuery.data?.data.map((member) => ({
                              label: member.fullName,
                              value: member.id,
                            })) ?? []
                          }
                          onChange={(value: Option) => onChange(value)}
                          value={value}
                          error={errors.actions?.[index]?.assignedTo?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={'flex-1 border p-2'}>
                    <Controller
                      control={control}
                      name={`actions.${index}.dueDate` as const}
                      render={({ field: { onChange, value } }) => (
                        <Datepicker
                          id={`action_${field.id}_dueDate`}
                          label={'Due date'}
                          hideLabel
                          placeholder={'Due date'}
                          onChange={(date: Date) => onChange(dayjs(date).format('YYYY-MM-DD'))}
                          selected={null !== value ? dayjs(value, 'YYYY-MM-DD').toDate() : null}
                          error={errors.actions?.[index]?.dueDate?.message}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <ButtonIcon
                icon={'Trash'}
                size={'xsm'}
                isFree
                mainColor={'muted'}
                label={'Remove'}
                onClick={() => actionsFieldArray.remove(index)}
              />
            </div>
          ))}
        </ModalComponents.Body>
        <ModalComponents.Footer>
          <ModalComponents.CancelButton onClick={() => toggle(false)} />
          <ModalComponents.ConfirmButton label={actions.length > 0 ? 'Save' : 'Add'} type={'submit'} />
        </ModalComponents.Footer>
      </form>
    </Modal>
  );
}

interface Props extends Pick<ModalProps, 'toggle'> {
  group: string;
  actions: MeetingFormValues['actions'];
  onSubmit: (actions: MeetingFormValues['actions']) => void;
}
