import React, { useState } from 'react';
import Tippy from '@tippyjs/react';
import styled, { DefaultTheme, ThemeProps } from 'styled-components';
import routeBuilder, { PathType } from '../../../service/routeBuilder';
import { SystemIconType } from '../../../resources/types';
import { getFocusStyle, getTransparentButtonStyle } from '../../../helpers/styles';
import { LinkStyled, RouterStyled } from '../../../global/link/link.styled';
import { ChevronHamburger } from '../../../global/hamburger';
import Tag from '../../../global/tag/tag';
import Icon from '../../../global/icon/icon';
import {
  isStcp,
  niotRole,
  ROLE_ASSISTANT_GT_COORDINATOR,
  ROLE_LABELS,
  ROLE_MAT_COORDINATOR,
  ROLE_SCHOOL_COORDINATOR,
  ROLE_TEACHER,
  ROLE_TEACHER_FREE,
  RolesType,
  SUBSCRIPTION_TYPE_PAID,
} from '../../../resources/roles';
import { DEFAULT_TRANSITION_DURATION, DEFAULT_TRANSITION_FUNC } from '../../../global/transitions/transitions.types';
import { GLOBAL_ICONS, SIZE_HEIGHT, SIZE_ICON } from '../../../resources/vars';
import { DEFAULT_THEME } from '../../../Theme';
import { getAdvisor, postCreateChargebeePortalSession } from '../../../service/api';
import { ADVISOR_INFO } from '../../../service/queryKeys';
import { useMutation, useQuery } from 'react-query';
import useModal from '../../../hooks/useModal';
import UpgradeAccountModal from '../modals/UpgradeAccountModal';
import { toast } from 'react-toastify';

const ROLE_DESCRIPTION = 'Class teacher';

const UserMenu = ({ me }: UserMenuProps) => {
  const { data: advisorInfo, isFetched: advisorInfoIsFetched, isSuccess: advisorInfoIsSuccess } = useQuery(
    ADVISOR_INFO,
    () => getAdvisor(),
    {
      select: (data: any) => data.data.data,
      staleTime: Infinity,
      enabled: [ROLE_SCHOOL_COORDINATOR, ROLE_MAT_COORDINATOR].includes(me.role),
      retryOnMount: false,
    }
  );

  const [toggleChevron, setToggleChevron] = useState(false);
  const windowTyped: any = window;

  const [upgradeAccountModal, toggleUpgradeAccountModal, setUpgradeAccountModalProps] = useModal((props: any) => (
    <UpgradeAccountModal {...props} />
  ));

  const userTag = niotRole(me);

  const stcpTag = isStcp(me);

  const MENU_ITEMS: {
    id: PathType | 'paymentManagement' | 'subscriptionManagement' | 'contactAdvisor' | 'upgradeAccount' | 'contactTeam';
    label: string;
    icon: SystemIconType;
    condition?: boolean;
  }[] = [
    { id: 'userDetails', label: 'Edit my details', icon: 'user', condition: !stcpTag },
    {
      id: 'downloadInfoPack',
      label: 'Download information pack',
      icon: 'book',
      condition: ROLE_TEACHER_FREE === me.role,
    },
    {
      id: 'upgradeAccount',
      label: 'Enrol my school',
      icon: 'upgrade',
      condition: ROLE_TEACHER_FREE === me.role,
    },
    {
      id: 'contactTeam',
      label: 'Speak to one of the team',
      icon: 'chat',
      condition: ROLE_TEACHER_FREE === me.role && me.role_type != ROLE_DESCRIPTION,
    },
    {
      id: 'schoolDetails',
      label: 'Edit school details',
      icon: 'school',
      condition: [ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role),
    },
    {
      id: 'paymentManagement',
      label: 'Edit payment method',
      icon: 'card',
      condition:
        SUBSCRIPTION_TYPE_PAID === me.subscription_type &&
        !['NIOT', 'REGIONAL_LEAD', 'ASSOCIATE_COLLEGE', 'INDUCTION_LEAD'].includes(userTag),
    },
    {
      id: 'subscriptionManagement',
      label: 'Edit my subscription',
      icon: 'subscriptions',
      condition:
        SUBSCRIPTION_TYPE_PAID === me.subscription_type &&
        !['NIOT', 'REGIONAL_LEAD', 'ASSOCIATE_COLLEGE', 'INDUCTION_LEAD'].includes(userTag),
    },
    {
      id: 'groupManagement',
      label: 'My teams',
      icon: 'group',
      condition:
        ([ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role) ||
          (me.role === ROLE_TEACHER && me.school_user_id != null)) &&
        !['MENTOR', 'INDUCTION_LEAD', 'ECT'].includes(userTag) &&
        !stcpTag,
    },
    {
      id: 'contactAdvisor',
      label: 'Contact my advisor',
      icon: 'envelope',
      condition:
        [ROLE_SCHOOL_COORDINATOR, ROLE_MAT_COORDINATOR].includes(me.role) &&
        advisorInfoIsFetched &&
        advisorInfoIsSuccess,
    },
  ];

  return (
    <React.Fragment>
      <Tippy
        trigger={'click'}
        appendTo={document.body}
        interactive={true}
        interactiveBorder={50}
        arrow={false}
        theme={'light-border'}
        animation={'shift-away'}
        onTrigger={() => setToggleChevron(true)}
        onUntrigger={() => setToggleChevron(false)}
        onClickOutside={() => setToggleChevron(false)}
        content={
          <div className={'text-base'} style={{ margin: '-5px -9px', minWidth: 320 }}>
            <div className={'py-3 px-4'}>
              <div className={'flex items-center justify-between'}>
                <p className={'font-bold'}>{me.first_name + ' ' + me.last_name}</p>
                <Tag content={ROLE_LABELS[me.role as RolesType]} mainColor={'info'} />
              </div>
              <p className={'text-sm text-muted'}>{me.email}</p>
            </div>
            <div className={'border-t-2 border-b-2'}>
              {MENU_ITEMS.filter((element) =>
                undefined === element.condition || element.condition ? element : null
              ).map((element) => {
                if (element.id === 'paymentManagement') {
                  return (
                    <div
                      key={`menu_${element.id}`}
                      className={'flex items-center justify-between hover:bg-gray-100 py-3 px-4'}
                    >
                      <LinkStyled
                        onClick={(e: any) => {
                          e.preventDefault();
                          const chargebeeInstance = windowTyped.Chargebee.getInstance();

                          chargebeeInstance.setPortalSession(() =>
                            postCreateChargebeePortalSession().then((response) => response.data.data)
                          );

                          const cbPortal = chargebeeInstance.createChargebeePortal();
                          cbPortal.open({
                            close() {
                              chargebeeInstance.logout();
                            },
                          });
                        }}
                        className={'block flex-grow'}
                      >
                        {element.label}
                      </LinkStyled>
                      <Icon
                        icon={GLOBAL_ICONS[element.icon]}
                        container={false}
                        elementSize={SIZE_ICON['sm']}
                        color={'muted'}
                      />
                    </div>
                  );
                }

                if (element.id === 'upgradeAccount') {
                  return (
                    <React.Fragment key={`menu_${element.id}`}>
                      {/*{upgradeAccountModal}*/}
                      <div
                        key={`menu_${element.id}`}
                        className={'flex items-center justify-between hover:bg-gray-100 py-3 px-4'}
                      >
                        <LinkStyled
                          href={'https://toolkit.greatteaching.com/register?r=school'}
                          target={'_blank'}
                          /*onClick={(e: any) => {
                            e.preventDefault();

                            setUpgradeAccountModalProps({ title: 'Upgrade Account' });
                            toggleUpgradeAccountModal(true);
                          }}*/
                        >
                          {element.label}
                        </LinkStyled>
                        <Icon
                          icon={GLOBAL_ICONS[element.icon]}
                          container={false}
                          elementSize={SIZE_ICON['sm']}
                          color={'muted'}
                        />
                      </div>
                    </React.Fragment>
                  );
                }

                if (element.id === 'subscriptionManagement') {
                  return (
                    <div
                      key={`menu_${element.id}`}
                      className={'flex items-center justify-between hover:bg-gray-100 py-3 px-4'}
                    >
                      <LinkStyled href={'https://share.hsforms.com/1fY7HDcQpRGivRoJ4AnkvYA1eppz'} target={'_blank'}>
                        {element.label}
                      </LinkStyled>
                      <Icon
                        icon={GLOBAL_ICONS[element.icon]}
                        container={false}
                        elementSize={SIZE_ICON['sm']}
                        color={'muted'}
                      />
                    </div>
                  );
                }

                if (element.id === 'contactAdvisor') {
                  return (
                    <div
                      key={`menu_${element.id}`}
                      className={'flex items-center justify-between hover:bg-gray-100 py-3 px-4'}
                    >
                      <LinkStyled href={'mailto:' + advisorInfo.email + '?body=Hi ' + advisorInfo.name + ','}>
                        {element.label}
                      </LinkStyled>
                      <Icon
                        icon={GLOBAL_ICONS[element.icon]}
                        container={false}
                        elementSize={SIZE_ICON['sm']}
                        color={'muted'}
                      />
                    </div>
                  );
                }

                if (element.id === 'contactTeam') {
                  return (
                    <div
                      key={`menu_${element.id}`}
                      className={'flex items-center justify-between hover:bg-gray-100 py-3 px-4'}
                    >
                      <LinkStyled
                        href={'https://meetings.hubspot.com/alex454/speak-to-one-of-the-team-starter-account'}
                        target={'_blank'}
                      >
                        {element.label}
                      </LinkStyled>
                      <Icon
                        icon={GLOBAL_ICONS[element.icon]}
                        container={false}
                        elementSize={SIZE_ICON['sm']}
                        color={'muted'}
                      />
                    </div>
                  );
                }

                if (element.id === 'downloadInfoPack') {
                  return (
                    <div
                      key={`menu_${element.id}`}
                      className={'flex items-center justify-between hover:bg-gray-100 py-3 px-4'}
                    >
                      <LinkStyled
                        href={
                          'https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/Info%20packs%202024/EBE_GTT_Unpacked_DIGITAL-FINAL-DRAFT.pdf'
                        }
                        target={'_blank'}
                      >
                        {element.label}
                      </LinkStyled>
                      <Icon
                        icon={GLOBAL_ICONS[element.icon]}
                        container={false}
                        elementSize={SIZE_ICON['sm']}
                        color={'muted'}
                      />
                    </div>
                  );
                }

                return (
                  <div
                    key={`menu_${element.id}`}
                    className={'flex items-center justify-between hover:bg-gray-100 py-3 px-4'}
                  >
                    <RouterStyled to={routeBuilder(element.id).generate()} className={'block flex-grow'}>
                      {element.label}
                    </RouterStyled>
                    <Icon
                      icon={GLOBAL_ICONS[element.icon]}
                      container={false}
                      elementSize={SIZE_ICON['sm']}
                      color={'muted'}
                    />
                  </div>
                );
              })}
            </div>
            <div className={'flex items-center justify-between hover:bg-gray-100 py-3 px-4'}>
              <RouterStyled
                onClick={() => updateLastlogin()}
                to={routeBuilder('logout').generate()}
                className={'block flex-grow'}
              >
                Logout
              </RouterStyled>
              <Icon icon={GLOBAL_ICONS['logout']} container={false} elementSize={SIZE_ICON['sm']} color={'muted'} />
            </div>
          </div>
        }
      >
        <UserMenuButtonStyled
          id={'stonly_user_menu'}
          className={'flex items-center border border-gray-300 hover:bg-light rounded-app'}
        >
          <div className={'truncate'}>
            <span className={'hidden md:block'}>{me.first_name + ' ' + me.last_name}</span>
            <span className={'block md:hidden'}>{me.first_name.charAt(0) + '. ' + me.last_name}</span>
          </div>
          <div>
            <ChevronHamburger as={'div'} toggled={toggleChevron} transparent />
          </div>
        </UserMenuButtonStyled>
      </Tippy>
    </React.Fragment>
  );
};

type UserMenuProps = {
  me: Record<any, any>;
};

const UserMenuButtonStyled = styled('button')<ThemeProps<DefaultTheme>>`
  ${getTransparentButtonStyle(false)}
  height: ${SIZE_HEIGHT['md']}px;
  padding-left: ${SIZE_HEIGHT['md'] / 2}px;
  transition: all ${DEFAULT_TRANSITION_FUNC} ${DEFAULT_TRANSITION_DURATION}ms;

  &:focus {
    ${(props) => getFocusStyle(props.theme.colors.primary)}
  }
`;

UserMenuButtonStyled.defaultProps = {
  theme: DEFAULT_THEME,
};

export default UserMenu;
