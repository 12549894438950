import Section from '../../common/components/section';
import React, { useContext, useState } from 'react';
import ButtonIcon from '../../../global/buttonIcon/buttonIcon';
import { useQuery } from 'react-query';
import { ACADEMIC_YEARS, MY_GROUPS } from '../../../service/queryKeys';
import { getAcademicYears, getTeacherMemberGroups, getTeacherMemberMentees } from '../../../service/api';
import Icon from '../../../global/icon/icon';
import Button from '../../../global/button/button';
import Accordion from '../../../global/accordion/accordion';
import dayjs from 'dayjs';
import { TeamFormData } from './modals/TeamForm.modal';
import { MeetingsFormData } from './modals/MeetingsForm.modal';
import MeetingAgendaFormModal, { MeetingAgendaToEdit } from './modals/MeetingAgendaForm.modal';
import Selector from '../../../form/select/selector';
import { ApiResponse, Option } from '../../../@types/global';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { MeetingPathway } from '../../../@types/Entity/MeetingPathway';
import { Group } from '../../../@types/Entity/Group';
import { useNavigate } from 'react-router-dom';
import routeBuilder from '../../../service/routeBuilder';
import { Me } from '../../../@types/Entity/Me';
import Alert from '../../../global/alert/alert';
import TeamsModeContext, { TEAMS_MODE_CONTEXT_TEAMS_MODES } from '../../../contexts/TeamsContext/TeamsModeContext';
import { LinkStyled } from '../../../global/link/link.styled';
import { getDownloadMeetingFile } from '../../../service/api/groupsApi';
import { ROLE_ASSISTANT_GT_COORDINATOR, ROLE_SCHOOL_COORDINATOR, ROLE_TEACHER_FREE } from '../../../resources/roles';

dayjs.extend(customParseFormat);

const today = dayjs();

export default function TeamsList({ onEditTeamClick, onEditMeetingsClick, me }: Props) {
  const { teamsMode } = useContext(TeamsModeContext);
  const navigate = useNavigate();

  const [meetingOpen, setMeetingOpen] = useState<string | null>(null);
  const [isMeetingAgendaFormModalOpen, setIsMeetingAgendaFormModalOpen] = useState(false);
  const [teamMeetingsAcademicYear, setTeamMeetingsAcademicYear] = useState<Record<string, any>>({});
  const [meetingAgendaFormModalData, setMeetingAgendaFormModalData] = useState<{
    meetingAgendaToEdit: MeetingAgendaToEdit;
    meetingPathway: MeetingPathway | null;
  } | null>(null);

  const myGroupsQuery = useQuery<ApiResponse<Group[]>>(
    [MY_GROUPS, { mode: teamsMode }],
    () =>
      TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode
        ? getTeacherMemberGroups()
        : getTeacherMemberMentees(),
    {
      placeholderData: {
        data: [],
      },
    }
  );

  const academicYearsOptionsQuery = useQuery([ACADEMIC_YEARS], () => getAcademicYears(), {
    select: (data) =>
      data?.data.map((academicYear) => ({
        label: academicYear.name,
        value: academicYear.id,
        original: {
          ...academicYear,
          startDate: dayjs(academicYear.startDate, 'DD/MM/YYYY').startOf('day'),
          endDate: dayjs(academicYear.endDate, 'DD/MM/YYYY').endOf('day'),
        },
      })),
  });

  const defaultAcademicYear = academicYearsOptionsQuery.data?.find(
    (option) =>
      option.original.startDate.toDate() <= today.toDate() &&
      option.original.endDate.endOf('day').toDate() >= today.toDate()
  );

  return (
    <>
      {isMeetingAgendaFormModalOpen && null !== meetingAgendaFormModalData && (
        <MeetingAgendaFormModal
          meetingAgendaToEdit={meetingAgendaFormModalData.meetingAgendaToEdit}
          meetingPathway={meetingAgendaFormModalData.meetingPathway}
          toggle={() => {
            setMeetingAgendaFormModalData(null);
            setIsMeetingAgendaFormModalOpen(false);
          }}
        />
      )}
      {0 === (myGroupsQuery.data?.data.length ?? 0) && (
        <Alert type="info" className={'mt-5'}>
          {TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode
            ? 'You are not yet a member of any team'
            : 'Add a mentee to get started'}
        </Alert>
      )}
      {myGroupsQuery.data?.data.map((team) => {
        const selectedAcademicYear = teamMeetingsAcademicYear[team.id] ?? defaultAcademicYear ?? null;
        const sortedMeetings = team.meetings.sort((a, b) => dayjs(a.scheduledAt).unix() - dayjs(b.scheduledAt).unix());
        const sortedFilteredMeetings = sortedMeetings.filter((meeting) => {
          const scheduledAt = dayjs(meeting.scheduledAt);

          if (
            null !== selectedAcademicYear &&
            !(
              selectedAcademicYear.original.startDate <= scheduledAt &&
              selectedAcademicYear.original.endDate >= scheduledAt
            )
          ) {
            return false;
          }

          return !meeting.isArchived;
        });

        const isPartOfTeam = team.members.some((member) => member.id === me?.id);

        return (
          <div key={team.id} className={'mt-10'}>
            {((TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode &&
              (me?.great_teaching_lead || me?.role === ROLE_SCHOOL_COORDINATOR || me?.role === ROLE_ASSISTANT_GT_COORDINATOR)) ||
              (TEAMS_MODE_CONTEXT_TEAMS_MODES.mentoringTeams === teamsMode && me?.mentor)) && (
              <div className={'flex justify-end'}>
                <ButtonIcon
                  icon={'Pencil'}
                  color={'primary'}
                  isFree
                  label={'Edit'}
                  onClick={() =>
                    onEditTeamClick({
                      id: team.id,
                      name: team.name,
                      members: team.members.map((member) => ({
                        label: member.fullName,
                        value: member.id,
                      })),
                      focusedElement:
                        null !== team.focusedElement
                          ? {
                              label: team.focusedElement.title,
                              value: team.focusedElement.value,
                            }
                          : null,
                      meetingPathway:
                        null !== team.meetingPathway
                          ? {
                              label: team.meetingPathway.title,
                              value: team.meetingPathway.id,
                            }
                          : null,
                      cohort: team.cohort,
                    })
                  }
                />
                {/* TODO: Implement Archive */}
                {/*<ButtonIcon icon={'Archive'} color={'primary'} isFree label={'Archive'} />*/}
                {/* TODO: Implement Delete */}
                {/*<ButtonIcon icon={'Trash'} color={'primary'} isFree label={'Delete'} />*/}
              </div>
            )}

            <Section size={'md'}>
              <div className={'flex justify-between'}>
                <div>
                  <h5>{me?.role === ROLE_TEACHER_FREE ? me?.first_name + '\'s' + ' Teams'  : team.name}</h5>
                  {team.focusedElement?.title}
                </div>

                <Button
                  size={'sm'}
                  onClick={() =>
                    TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode
                      ? navigate(routeBuilder('greatTeachingTeamsWorkspace').generate(team.id))
                      : navigate(routeBuilder('mentoringTeamsWorkspace').generate(team.id))
                  }
                >
                  {TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode
                    ? 'Team workspace'
                    : 'ECF shared workspace'}
                </Button>
              </div>

              {TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode && (
                <div className={'mt-5'}>
                  <ul>
                    {team.members.map((member) => (
                      <li key={member.id} className={'flex items-center'}>
                        <Icon icon={'PersonCircle'} container={false} className={'mr-2'} /> {member.fullName}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className={'mt-5'}>
                <div className={'flex justify-between mb-2'}>
                  <div style={{ minWidth: '200px' }}>
                    <Selector
                      id={`academic-year-${team.id}`}
                      placeholder={'Academic year'}
                      label={'Academic year'}
                      hideLabel
                      options={academicYearsOptionsQuery.data}
                      onChange={(selected: Option) =>
                        setTeamMeetingsAcademicYear((prev) => ({
                          ...prev,
                          [team.id]: selected,
                        }))
                      }
                      value={selectedAcademicYear}
                      isClearable={false}
                    />
                  </div>
                  <div>
                    {((teamsMode === TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams && isPartOfTeam) ||
                      me?.mentor) && (
                      <Button
                        size={'sm'}
                        onClick={() =>
                          onEditMeetingsClick({
                            id: team.id,
                            meetings: sortedMeetings.map((meeting) => {
                              const scheduledAtDayJs = dayjs(meeting.scheduledAt);

                              return {
                                id: meeting.id,
                                title: meeting.title,
                                date: scheduledAtDayJs.format('YYYY-MM-DD'),
                                time: scheduledAtDayJs.format('HH:mm'),
                                location: meeting.location,
                                isArchived: meeting.isArchived,
                              };
                            }),
                          })
                        }
                      >
                        {0 === team.meetingsCount.active || 0 === sortedFilteredMeetings.length ? 'Create' : 'Edit'}{' '}
                        meetings
                      </Button>
                    )}
                  </div>
                </div>

                {0 === sortedFilteredMeetings.length && "This team doesn't have meetings yet."}

                {sortedFilteredMeetings.map((meeting) => {
                  const hasAgenda =
                    0 < meeting.prerequisites.length || 0 < meeting.agendaItems.length || 0 < meeting.resources.length;

                  return (
                    <Accordion
                      key={meeting.id}
                      title={
                        <div className={'grid grid-cols-3'}>
                          <div>{meeting.title}</div>
                          <div>{dayjs(meeting.scheduledAt).format('ddd DD MMM')}</div>
                          <div className={'flex justify-end'}>
                            {meeting.isFinished && <Icon icon={'CheckCircle'} color={'success'} container={false} />}
                          </div>
                        </div>
                      }
                      isOpen={meeting.id === meetingOpen}
                      handleOpen={() => setMeetingOpen((prev) => (meeting.id !== prev ? meeting.id : null))}
                    >
                      <div className={'flex justify-end'}>
                        {((teamsMode === TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams && isPartOfTeam) ||
                          me?.mentor) && (
                          <Button
                            onClick={() => {
                              setMeetingAgendaFormModalData({
                                meetingAgendaToEdit: meeting,
                                meetingPathway: team.meetingPathway,
                              });
                              setIsMeetingAgendaFormModalOpen(true);
                            }}
                            asLink={hasAgenda}
                            size={hasAgenda ? 'xsm' : undefined}
                          >
                            {hasAgenda ? 'Edit' : 'Add agenda'}
                          </Button>
                        )}
                      </div>

                      <div className={'grid gap-4 grid-cols-3 justify-between'}>
                        <div>
                          <h6>Prerequisites</h6>
                          <ul className={'list-disc list-inside'}>
                            {meeting.prerequisites.map((prerequisite) => (
                              <li key={prerequisite.id}>{prerequisite.title}</li>
                            ))}
                          </ul>
                        </div>

                        <div>
                          <h6>Agenda</h6>
                          <ul className={'list-disc list-inside'}>
                            {meeting.agendaItems.map((agendaItem) => (
                              <li key={agendaItem.id}>{agendaItem.title}</li>
                            ))}
                          </ul>
                        </div>

                        <div>
                          <h6>Resources</h6>
                          <ul className={'list-disc list-inside'}>
                            {meeting.resources.map((resource) => (
                              <li key={resource.id}>
                                {resource.url ? (
                                  <LinkStyled href={resource.url} target={'_blank'}>
                                    {resource.title}
                                  </LinkStyled>
                                ) : (
                                  <LinkStyled onClick={() => getDownloadMeetingFile(resource.id!, resource.name!)}>
                                    {resource.name}
                                  </LinkStyled>
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      {/*{hasAgenda && (*/}
                      {/*  <div className={'flex justify-end mt-10'}>*/}
                      {/*    /!* TODO: Implement *!/*/}
                      {/*    <Button size={'sm'} onClick={() => console.log('Implement')}>*/}
                      {/*      {TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode*/}
                      {/*        ? 'Share with team'*/}
                      {/*        : 'Share with mentee'}*/}
                      {/*    </Button>*/}
                      {/*  </div>*/}
                      {/*)}*/}
                    </Accordion>
                  );
                })}
              </div>
            </Section>
          </div>
        );
      })}
    </>
  );
}

interface Props {
  onEditTeamClick: (group: TeamFormData) => void;
  onEditMeetingsClick: (group: MeetingsFormData) => void;
  me: Me | null;
}
