import React from 'react';
import VideoSection from '../sections/videoSection';
import { Section } from '../../common';
import { SchoolSummary } from '../sections';
import PlanTeacherDashboard from '../../organisation/sections/planTeacherDashboard';
import DashboardWebinar from '../../zoom/components/dashboardWebinar';
import { CommunityNewsfeed } from '../../index';

const SchoolDashboard = ({ data, name, email }: { data: any; name: string; email: string }) => {
  return (
    <>
      {data.zoom && <DashboardWebinar webinarData={data.zoom} name={name} email={email} />}
      <Section
        className={'field-mb'}
        headline={
          <div>
            Current school information
            <div className={'text-sm'}>
              {data?.academic_year ? (
                `${data.academic_year.start_year}-${data.academic_year.end_year}`
              ) : (
                <i>No academic year set</i>
              )}
            </div>
            <div className={'text-sm'}>{data?.development_period?.name}</div>
          </div>
        }
      >
        {data?.welcome ? <VideoSection video={data.welcome} /> : <SchoolSummary data={data} />}
        <PlanTeacherDashboard isCoordinator={false}></PlanTeacherDashboard>
      </Section>
      <div className="flex flex-col lg:flex-row gap-8 field-mb">
        <CommunityNewsfeed width={'lg'} />
        {data.tutorial && (
          <Section size={null} headline={'Tutorial'} className={'w-full lg:w-2/4'}>
            <VideoSection video={data.tutorial} />
          </Section>
        )}
      </div>
    </>
  );
};

export default SchoolDashboard;
