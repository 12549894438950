import React, { useEffect, useState } from 'react';
import { isStcp, niotRole, ROLE_LABELS, ROLE_SYS_ADMIN, TAG_STCP_ADMIN } from '../../resources/roles';
import { ColumnType } from '../../global/table/table.types';
import DashboardLayout from '../../layout/dashboard';
import Choice from '../../form/choice/choice';
import DefaultTable from '../../global/table/defaultTable';
import Search from '../../form/search/search';
import { ArchivedName } from '../../global/table/components';
import { Section } from '../common';
import { Layout } from '../../global/table/components/archived';
import { Me } from '../../@types/Entity/Me';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { USERS_MONITORING_LIST } from '../../service/queryKeys';
import { getUsersList } from '../../service/api';
import { GLOBAL_ICONS, SIZE_ICON } from '../../resources/vars';
import Icon from '../../global/icon/icon';
import useModal from '../../hooks/useModal';
import { DevelopmentActivitiesModal } from '../users/modals';
import { Checkbox } from 'pretty-checkbox-react';
import { UserType } from '../users/userManagementPage';
import { JoinButton } from '../../global/button/common';
import { deleteGroup, postOrPutGroup, PostOrPutGroupData } from '../../service/api/groupsApi';
import { toast } from 'react-toastify';
import Button from '../../global/button/button';
import { downloadMontoringUsersCsv } from '../../service/api/adminApi';
import { MoreInfo } from '../../global/tooltip/common';
import Tooltip from '../../global/tooltip/tooltip';
import Selector from '../../form/select/selector';
import ButtonIcon from '../../global/buttonIcon/buttonIcon';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { Bar, BarChart, CartesianGrid, Cell, Tooltip as Tip, YAxis } from 'recharts';
import Tab from '../../global/tab/tab';
import ReassignGroupModal from './modals/ReassignGroupModal';

const COLUMNS: ColumnType[] = [
  { id: 'first_name', label: 'First name', isSortable: true },
  { id: 'last_name', label: 'Last name', isSortable: true },
  { id: 'email', label: 'Email', isSortable: true },
  { id: 'organisation', label: 'Organisation', isSortable: true },
  { id: 'role', label: 'Role', isSortable: true },
  { id: 'mentor', label: 'Mentor' },
  {
    id: 'self_study',
    label: (
      <div className={'flex gap-2'}>
        % Self study
        <MoreInfo content={'% self-study completed by module'} />
      </div>
    ),
    isSortable: false,
  },
  {
    id: 'self_study2',
    label: (
      <div className={'flex gap-2'}>
        Terms
        <MoreInfo content={'% self-study completed by term'} />
      </div>
    ),
  },
  { id: 'actions', label: 'Info', isSortable: true },
  { id: 'last_login', label: 'Last login', isSortable: true },
];

const STCP_COLUMNS: ColumnType[] = [
  { id: 'first_name', label: 'First name', isSortable: true },
  { id: 'last_name', label: 'Last name', isSortable: true },
  { id: 'email', label: 'Email', isSortable: true },
  {
    id: 'self_study',
    label: (
      <div className={'flex gap-2'}>
        % Self study
        <MoreInfo content={'% self-study completed by module'} />
      </div>
    ),
    isSortable: false,
  },
  { id: 'actions', label: 'Info', isSortable: true },
  { id: 'last_login', label: 'Last login', isSortable: true },
];

export function MonitoringPage({ me }: { me: Me }) {
  const [searchValue, setSearchValue] = useState<string>('');
  const [order, setOrder] = useState('');
  const [page, setPage] = useState(1);
  const [show, setShow] = useState<string>('active');
  const [selectedUsers, setSelectedUsers] = useState<Array<UserType>>([]);

  const [searchTag, setSearchTag] = useState<'mentor' | 'ect' | undefined>(undefined);

  const [showAc10Only, setShowAc10Only] = useState<boolean>(false);

  const [allowToggle, setAllowToggle] = useState<boolean>(false);

  const [ceo, setCeo] = useState<boolean | undefined>(undefined);

  const [layout, setLayout] = useState('NIOT_ONLY');

  const usersQuery = useQuery(
    [USERS_MONITORING_LIST, order, searchValue, show, page, searchTag, showAc10Only, ceo],
    () =>
      getUsersList({
        order: order,
        filter: searchValue,
        show: show,
        page,
        monitoring: true,
        tag: searchTag,
        ac_10: showAc10Only,
        ceo: ceo,
      }),
    {
      select: (data) => data.data,
      enabled: ceo != undefined,
      staleTime: Infinity,
    }
  );

  const queryClient = useQueryClient();
  const groupMutation = useMutation(({ data }: { data: PostOrPutGroupData }) => postOrPutGroup(data, true, true), {
    onSuccess: () => {
      queryClient.invalidateQueries([USERS_MONITORING_LIST]);
      toast.success('Team successfully created.');
      setSelectedUsers([]);
    },
  });

  const deleteGroupMutation = useMutation(deleteGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries([USERS_MONITORING_LIST]);
      toast.success('Team removed');
      setSelectedUsers([]);
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      toast.error(error.response?.data.message ?? 'An error has occurred.');
    },
  });

  const [reassignGroupCreationModal, toggleReassignGroupModal, setReassignGroupModalProps] = useModal(
    (props: any) => <ReassignGroupModal {...props} />,
    {
      title: 'Reassign group ECT',
    }
  );

  const [developmentActivitiesModal, toggleDevelopmentActivitiesModal, setDevelopmentActivitiesProps] = useModal(
    (props: any) => <DevelopmentActivitiesModal {...props} />,
    {
      title: 'Development activities',
      size: 'xlg',
      preventCloseOnClickOnMask: true,
    }
  );

  let tableColumns = COLUMNS;
  if (me.is_induction_lead) {
    tableColumns = [{ id: 'select', label: '' }, ...COLUMNS];
  }

  const userTag = niotRole(me);

  const isAdmin = ['REGIONAL_LEAD', 'NIOT'].includes(userTag) || me.role === 'ROLE_SYS_ADMIN';

  const isNiotOrSysAdmin = userTag === 'NIOT' || me.role === 'ROLE_SYS_ADMIN';

  useEffect(() => {
    if (isNiotOrSysAdmin) {
      tableColumns.splice(4, 0, { id: 'region', label: 'Region' });
    }
    if (isAdmin) {
      tableColumns.splice(isNiotOrSysAdmin ? 5 : 4, 0, {
        id: 'associate_college',
        label: 'Delivery partner',
        isSortable: false,
      });
    }
  }, []);

  useEffect(() => {
    const isAssociateCollege10Exist = usersQuery.data?.data?.associate_college_10;
    if (typeof isAssociateCollege10Exist === 'boolean' && isAssociateCollege10Exist) setAllowToggle(true);
  }, [usersQuery]);

  const CustomTooltip = ({ active, payload, label }: { active?: any; payload?: any; label?: any }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-white opacity-90 p-2">
          <div className="flex">
            <p className="label mx-auto">{Math.round(payload[0].payload.uv)}%</p>
          </div>
          <div className="flex">
            <p className="desc mx-auto">{payload[0].payload.label}</p>
          </div>
        </div>
      );
    }

    return null;
  };

  const extractData = (data: any, type: string) => {
    if (type === 'module')
      return [
        {
          name: 'module_one',
          uv: data.self_studies?.module_one ?? 0,
          label: 'Module one',
          offline: data.self_studies.offline_study_module_one,
        },
        {
          name: 'module_two',
          uv: data.self_studies?.module_two ?? 0,
          label: 'Module two',
          offline: data.self_studies.offline_study_module_two,
        },
        {
          name: 'module_three',
          uv: data.self_studies?.module_three ?? 0,
          label: 'Module three',
          offline: data.self_studies.offline_study_module_three,
        },
        {
          name: 'module_four',
          uv: data.self_studies?.module_four ?? 0,
          label: 'Module four',
          offline: data.self_studies.offline_study_module_four,
        },
        {
          name: 'module_five',
          uv: data.self_studies?.module_five ?? 0,
          label: 'Module five',
          offline: data.self_studies.offline_study_module_five,
        },
        {
          name: 'module_six',
          uv: data.self_studies?.module_six ?? 0,
          label: 'Module six',
          offline: data.self_studies.offline_study_module_six,
        },
      ];
    else if (type === 'term')
      return [
        {
          name: 'term_one',
          uv: data.self_studies?.term_one ?? 0,
          label: 'Term one',
        },
        {
          name: 'term_two',
          uv: data.self_studies?.term_two ?? 0,
          label: 'Term two',
        },
        {
          name: 'term_three',
          uv: data.self_studies?.term_three ?? 0,
          label: 'Term three',
        },
      ];
  };

  const NIOT_TAB = 1;
  const STCP_TAB = 2;

  const TABS = [
    {
      value: NIOT_TAB,
      label: 'Early Career Framework',
    },
    {
      value: STCP_TAB,
      label: 'School Trust CEO Programme',
    },
  ];

  const [activeTab, setActiveTab] = useState<string | number>('loading');

  useEffect(() => {
    setOrder('');
    setSearchValue('');
    setShow('active');
    setPage(1);
    setSearchTag(undefined);
    setShowAc10Only(false);
    if (activeTab === STCP_TAB) setCeo(true);
    else if (activeTab === NIOT_TAB) setCeo(false);
  }, [activeTab]);

  useEffect(() => {
    if ((userTag != 'null' && isStcp(me) === TAG_STCP_ADMIN) || me.role === ROLE_SYS_ADMIN) {
      setLayout('NIOT_AND_STCP');
      setActiveTab(NIOT_TAB);
    } else if (userTag != 'null' && !isStcp(me)) {
      setLayout('NIOT_ONLY');
      setActiveTab(NIOT_TAB);
    } else if (userTag === 'null' && isStcp(me) === TAG_STCP_ADMIN) {
      setLayout('STCP_ONLY');
      setActiveTab(STCP_TAB);
    } else setLayout('loading');
  }, [userTag]);

  return (
    <DashboardLayout
      title={me.is_induction_lead ? 'Early Career Framework' : 'Monitoring'}
      pageAction={
        (layout === 'NIOT_ONLY' || (layout === 'NIOT_AND_STCP' && activeTab === NIOT_TAB)) && (
          <Button
            size={'sm'}
            onClick={() => {
              downloadMontoringUsersCsv({ order: order, filter: searchValue, show: show, name: 'users.csv' });
            }}
          >
            Export
          </Button>
        )
      }
    >
      {reassignGroupCreationModal}
      {layout === 'loading' ? (
        <></>
      ) : (
        <div className={'field-mb'}>
          <Section size={null}>
            {layout === 'NIOT_AND_STCP' && (
              <Tab
                items={TABS}
                active={activeTab}
                clickOnTab={(value) => {
                  setActiveTab(value);
                  if (value === STCP_TAB) setCeo(true);
                  else setCeo(false);
                }}
                parent={'monitoring'}
              ></Tab>
            )}
            <Section>
              <div className={'flex flex-wrap justify-between mb-5'}>
                <div className={'lg:flex lg:flex-row row-auto w-full gap-4 mb-8'}>
                  <div className={'lg:w-1/2 w-full mb-3 lg:mb-0'}>
                    <Search
                      id={'search_user'}
                      label={`Search User ${activeTab === 2 ? '' : '/ Organisation'}`}
                      hideLabel={false}
                      placeholder={`Search for name${activeTab === 2 ? ' or' : ','} email${
                        activeTab === 2 ? '' : ' or organisation'
                      }`}
                      value={searchValue}
                      onChange={(event) => setSearchValue(event.target.value)}
                      onRemove={() => setSearchValue('')}
                    />
                  </div>
                  {activeTab === 1 && (
                    <>
                      <div className={'lg:w-1/4 w-full mb-3 lg:mb-0'}>
                        <Selector
                          id={'roleTag'}
                          label={'Select Role Tag'}
                          placeholder={'Please select a role tag'}
                          value={[
                            { label: 'ECT', value: 'ect' },
                            {
                              label: 'Mentor',
                              value: 'mentor',
                            },
                          ].find((a: any) => a.value === searchTag)}
                          options={[
                            { label: 'ECT', value: 'ect' },
                            { label: 'Mentor', value: 'mentor' },
                          ]}
                          onChange={(options: any) => {
                            options === null ? setSearchTag(undefined) : setSearchTag(options.value);
                          }}
                          isClearable
                        />
                      </div>
                      <Choice
                        id={'deactivated-accounts'}
                        label={'View deactivated accounts'}
                        type={'switch'}
                        className={'pretty-no-margin'}
                        checked={show !== 'active'}
                        onChange={() => setShow((prevState) => (prevState === 'active' ? 'deactivated' : 'active'))}
                      />
                    </>
                  )}
                </div>
                {allowToggle && (
                  <Choice type={'switch'} id={'ac10_filter'} onChange={() => setShowAc10Only(!showAc10Only)}>
                    {showAc10Only ? 'Hide ' : 'Show only '}Oasis Community Learning teachers
                  </Choice>
                )}
              </div>
              {me.is_induction_lead && (
                <div className={'flex justify-end mb-4'}>
                  <JoinButton
                    id={'assignMentorButton'}
                    onClick={() => {
                      const nonMentor = selectedUsers.find((user) => !user.mentor);

                      groupMutation.mutate({
                        data: {
                          name: `${nonMentor?.first_name} ${nonMentor?.last_name}`,
                          members: selectedUsers.map((user) => user.id),
                          cohort: false,
                        },
                      });
                    }}
                    label={groupMutation.isLoading ? 'Loading...' : 'Assign mentor'}
                    disabled={
                      groupMutation.isLoading ||
                      !(
                        selectedUsers.length === 2 &&
                        selectedUsers.some((user) => user.mentor) &&
                        selectedUsers.some((user) => !user.mentor && !user.groups?.some((group) => group.mentee))
                      )
                    }
                  />
                </div>
              )}
              <DefaultTable
                apiHandled={true}
                setApiSort={setOrder}
                id={'user_management'}
                itemCounter={{
                  single: 'user',
                  plural: 'users',
                }}
                loading={usersQuery.isFetching}
                pagination={{
                  currentPage: page,
                  onPageChange: ({ selected }: { selected: number }) => setPage(selected + 1),
                  total: usersQuery.data?.meta?.total,
                  maxPage: usersQuery.data?.meta?.max_page,
                }}
                columns={
                  activeTab === 2
                    ? STCP_COLUMNS
                    : tableColumns.filter(
                        (column) => !me.is_induction_lead || !['region', 'organisation'].includes(column.id)
                      )
                }
                filteredBySearch={!!searchValue}
                rows={(usersQuery.data?.data?.users.filter((user: any) => typeof user != 'boolean') ?? []).map(
                  (user) => {
                    const menteeGroup = user.groups?.find((group) => group.mentee);
                    const cells = [];

                    if (me.is_induction_lead) {
                      cells.push({
                        id: 'select',
                        content: (
                          <div className={'pl-4'}>
                            <Checkbox
                              color={'primary'}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  setSelectedUsers([...selectedUsers, user]);
                                  return;
                                }

                                setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser.id !== user.id));
                              }}
                              checked={selectedUsers.map((u) => u.id).includes(user.id)}
                            />
                          </div>
                        ),
                      });
                    }

                    cells.push(
                      ...[
                        {
                          id: 'first_name',
                          content: (
                            <ArchivedName
                              archived={!user.active}
                              title={user.first_name}
                              item={'user'}
                              type={'deactivated'}
                            />
                          ),
                        },
                        {
                          id: 'last_name',
                          content: user.last_name,
                        },
                        {
                          id: 'email',
                          content: user.email,
                        },
                        activeTab === NIOT_TAB && {
                          id: 'organisation',
                          content: user.organisation,
                        },
                        /*{
                      id: 'region',
                      content: user.region ? user.region.name : 'No region',
                    },*/
                        activeTab === NIOT_TAB && {
                          id: 'role',
                          content: (
                            <Layout
                              title={user.is_induction_lead ? 'Induction Tutor' : ROLE_LABELS[user.role]}
                              customTag={user.mentor ? 'Mentor' : 'ECT'}
                              item={'user'}
                            />
                          ),
                        },
                        activeTab === NIOT_TAB && {
                          id: 'mentor',
                          content: !user.mentor ? (
                            menteeGroup ? (
                              <div className={'flex'}>
                                <span className={'mt-1'}>
                                  {menteeGroup.mentor.first_name} {menteeGroup.mentor.last_name}
                                </span>
                                {me.is_induction_lead && (
                                  <ButtonIcon
                                    icon={'XSquare'}
                                    isFree
                                    label={'Unpair mentor/ECT.'}
                                    onClick={() => {
                                      deleteGroupMutation.mutate(menteeGroup.id);
                                    }}
                                  />
                                )}
                                {me.is_induction_lead && (
                                  <ButtonIcon
                                    icon={'PencilSquare'}
                                    isFree
                                    label={'Reassign ECT.'}
                                    onClick={() => {
                                      setReassignGroupModalProps({ group: menteeGroup });
                                      toggleReassignGroupModal(true);
                                    }}
                                  />
                                )}
                              </div>
                            ) : (
                              'No'
                            )
                          ) : (
                            'n/a'
                          ),
                        },
                        {
                          id: 'self_study',
                          content: (
                            <>
                              <BarChart
                                style={{
                                  marginLeft: '-30px',
                                  marginTop: '-16px',
                                  marginRight: '20px',
                                  marginBottom: '-16px',
                                }}
                                width={200}
                                height={95}
                                data={extractData(user, 'module')}
                              >
                                <CartesianGrid />
                                <YAxis
                                  tick={{ fontSize: '15px' }}
                                  type="number"
                                  domain={[0, 101]}
                                  ticks={[50, 100]}
                                  tickCount={2}
                                  tickSize={3}
                                  tickMargin={0}
                                />
                                <Tip content={<CustomTooltip />} />
                                <Bar dataKey="uv">
                                  {extractData(user, 'module')?.map(
                                    (
                                      entry: {
                                        name: string;
                                        uv: any;
                                        label: string;
                                        offline?: any;
                                      },
                                      index
                                    ) => (
                                      <React.Fragment key={index}>
                                        <Cell
                                          fill={
                                            (activeTab === STCP_TAB && entry.offline === 'Yes') ||
                                            (activeTab === NIOT_TAB && entry.uv < 50)
                                              ? '#686a6c'
                                              : '#8884d8'
                                          }
                                          key={`cell-${index}`}
                                        />
                                      </React.Fragment>
                                    )
                                  )}
                                </Bar>
                              </BarChart>
                            </>
                          ),
                        },
                        activeTab === NIOT_TAB && {
                          id: 'self_study2',
                          content: (
                            <>
                              <BarChart
                                style={{ marginLeft: '-25px', marginTop: '-16px', marginBottom: '-16px' }}
                                width={140}
                                height={95}
                                data={extractData(user, 'term')}
                              >
                                <YAxis
                                  tick={{ fontSize: '15px' }}
                                  type="number"
                                  domain={[0, 101]}
                                  ticks={[50, 100]}
                                  tickCount={2}
                                  tickSize={3}
                                  tickMargin={0}
                                />
                                <CartesianGrid />
                                <Tip content={<CustomTooltip />} />
                                <Bar dataKey="uv">
                                  {extractData(user, 'term')?.map((entry, index) => (
                                    <React.Fragment key={index}>
                                      <Cell fill={entry.uv < 50 ? '#686a6c' : '#77af73'} key={`cell-${index}`} />
                                    </React.Fragment>
                                  ))}
                                </Bar>
                              </BarChart>
                            </>
                          ),
                        },
                        {
                          id: 'actions',
                          class: 'actions',
                          content: (
                            <ul className={'actions-list'}>
                              <li>
                                <Tooltip
                                  content={user.confirmed ? 'Teacher has logged in' : 'Teacher not yet logged in'}
                                >
                                  <Icon
                                    icon={GLOBAL_ICONS.user}
                                    elementSize={SIZE_ICON['md']}
                                    color={user.confirmed ? 'muted' : 'primary'}
                                    container={false}
                                    className={'ml-3'}
                                  />
                                </Tooltip>
                              </li>
                              {/*<li>*/}
                              {/*  <ActionButton.Custom*/}
                              {/*    buttonLabel={'Development activities'}*/}
                              {/*    icon={'plan'}*/}
                              {/*    onClick={() => {*/}
                              {/*      setDevelopmentActivitiesProps({*/}
                              {/*        development_activities: user.development_activities ?? [],*/}
                              {/*        preventCloseOnClickOnMask: true,*/}
                              {/*      });*/}
                              {/*      toggleDevelopmentActivitiesModal(true);*/}
                              {/*    }}*/}
                              {/*  />*/}
                              {/*</li>*/}
                            </ul>
                          ),
                        },
                        {
                          id: 'last_login',
                          content: user.last_login ? dayjs(user.last_login).format('DD/MM/YYYY HH:mm') : '',
                        },
                      ].filter((cell) => !me.is_induction_lead || !['region', 'organisation'].includes(cell.id))
                    );

                    if (isNiotOrSysAdmin && activeTab === NIOT_TAB) {
                      cells.splice(4, 0, {
                        id: 'region',
                        content: user.region ? user.region.name : 'No region',
                      });
                    }

                    if (isAdmin && activeTab != STCP_TAB) {
              const collegesWithout10 = user.school_associate_colleges
                ? user.school_associate_colleges.filter((college) => college.name != '10' && college.name)
                : [];

              cells.splice(isNiotOrSysAdmin ? 5 : 4, 0, {
                id: 'associate_college',
                content: collegesWithout10
                  .map((college: any, index: number) => {
                    return `${index != 0 ? ' ' : ''}${college.name}`;
                  })
                  .toString(),
              });
            }

                    return {
                      id: `${user.id}`,
                      class: !user.active ? 'archived' : '',
                      cells: activeTab === STCP_TAB ? cells.filter((cell) => cell != false) : cells,
                    };
                  }
                )}
                noDataMessage={'No users to manage.'}
              />
            </Section>
          </Section>
        </div>
      )}
      {developmentActivitiesModal}
    </DashboardLayout>
  );
}
