import React, { useContext } from 'react';
import { ModalProps } from '../../../../global/messages/modal/modal.types';
import Modal from '../../../../global/messages/modal/modal';
import ModalComponents from '../../../../global/messages/modal/modal.components';
import Input from '../../../../form/input/input';
import * as Yup from 'yup';
import { debounce } from 'lodash';
import { getCheckGroupNameIsValid, getPathwaysElements } from '../../../../service/api';
import { postOrPutGroup, PostOrPutGroupData } from '../../../../service/api/groupsApi';
import { Checkbox } from 'pretty-checkbox-react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApiResponse, Option } from '../../../../@types/global';
import Selector from '../../../../form/select/selector';
import { MY_GROUPS, PATHWAYS_ELEMENTS, SCHOOL_TEACHERS } from '../../../../service/queryKeys';
import { getSchoolTeachers, getSchoolTeachersWithoutMentee } from '../../../../service/api/schoolApi';
import { User } from '../../../../@types/Entity/User';
import TeamsModeContext, { TEAMS_MODE_CONTEXT_TEAMS_MODES } from '../../../../contexts/TeamsContext/TeamsModeContext';

const getCheckGroupNameIsValidDebounced = debounce(async (value, idToIgnore, resolve) => {
  try {
    const response = await getCheckGroupNameIsValid(value as string, idToIgnore);
    resolve(response.data.data.valid);
  } catch (error) {
    resolve(false);
  }
}, 500);

export default function TeamFormModal({ toggle, teamToEdit }: Props) {
  const { teamsMode } = useContext(TeamsModeContext);
  const queryClient = useQueryClient();

  const getSchoolTeachersQuery = useQuery<ApiResponse<User[]>>([SCHOOL_TEACHERS, { mode: teamsMode }], () =>
    TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode
      ? getSchoolTeachers()
      : getSchoolTeachersWithoutMentee()
  );

  const includeElement = '1.2';
  const getPathwaysElementsQuery = useQuery([PATHWAYS_ELEMENTS, includeElement], () =>
    getPathwaysElements(includeElement)
  );

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
  } = useForm<TeamFormData>({
    defaultValues: {
      name: '',
      members: [],
      focusedElement: null,
      cohort: false,
    },
    values: teamToEdit ?? undefined,
    resolver: yupResolver(Yup.object().shape({
      name: Yup.string()
        .max(64)
        .trim()
        .test(
          'name-required',
          'This is a required field.',
          (value) => {
            if (TEAMS_MODE_CONTEXT_TEAMS_MODES.mentoringTeams === teamsMode) {
              return true;
            }

            return value !== '' && value != null;
          })
        .test(
          'exists',
          'You already have a team with this name, please specify a different team name.',
          (value, input) => {
            if (TEAMS_MODE_CONTEXT_TEAMS_MODES.mentoringTeams === teamsMode) {
              return true;
            }

            return new Promise((resolve) => getCheckGroupNameIsValidDebounced(value, input.parent?.id ?? null, resolve))
          }),
      members: Yup.array().min(1, 'This is a required field.'),
      focusedElement: Yup.object().nullable(),
      cohort: Yup.bool(),
    })),
  });

  const postOrPutGroupMutation = useMutation(
    ({ data }: { data: PostOrPutGroupData }) =>
      postOrPutGroup(data, TEAMS_MODE_CONTEXT_TEAMS_MODES.mentoringTeams === teamsMode, true),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([MY_GROUPS]);

        toggle();
      },
    }
  );

  return (
    <Modal
      open
      toggle={() => toggle()}
      title={TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode ? 'Create new team' : 'Add new mentee'}
      size={'md'}
    >
      <form
        onSubmit={handleSubmit((values) =>
          postOrPutGroupMutation.mutate({
            data: {
              id: values.id ?? undefined,
              name: TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode ? values.name : values.members[0].label,
              members: values.members.map((option) => option.value),
              focusedElement: values.focusedElement?.value ?? null,
              cohort: values.cohort,
            },
          })
        )}
        noValidate
      >
        <ModalComponents.Body>
          {(TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode) && (
            <div className={'field-mb'}>
            <Input
              {...register('name')}
              required
              id={'name'}
              label={'Team name'}
              placeholder={'Please enter a name for this team'}
              error={errors.name?.message}
            />
          </div>)}
          <div className={'field-mb'}>
            <Controller
              control={control}
              name={'members'}
              render={({ field: { onChange, ...field } }) => (
                <Selector
                  {...field}
                  id={'members'}
                  label={
                    TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode ? 'Add teachers' : 'Add mentee'
                  }
                  error={errors.members?.message}
                  required
                  isMulti={TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode}
                  options={
                    getSchoolTeachersQuery.data?.data.map((teacher) => ({
                      label: teacher.fullName,
                      value: teacher.id,
                    })) ?? []
                  }
                  onChange={(value: Option) =>
                    onChange(TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode ? value : [value])
                  }
                  isLoading={!getSchoolTeachersQuery.isFetched || getSchoolTeachersQuery.isFetching}
                  disabled={!getSchoolTeachersQuery.isFetched || getSchoolTeachersQuery.isFetching}
                />
              )}
            />
          </div>
          {TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode && (
            <div className={'field-mb'}>
              <Controller
                control={control}
                name={'focusedElement'}
                render={({ field: { onChange, ...field } }) => (
                  <Selector
                    {...field}
                    id={'focusedElement'}
                    label={'Focused element'}
                    error={errors.focusedElement?.message}
                    options={getPathwaysElementsQuery.data?.data.data.elements ?? []}
                    onChange={(value: Option) => onChange(value)}
                  />
                )}
              />
            </div>
          )}
          {TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode && (
            <div className={'field-mb'}>
              <Checkbox {...register('cohort')} color={'primary'} id={'cohort'}>
                <strong>This is a cohort of teachers</strong>
              </Checkbox>
            </div>
          )}
        </ModalComponents.Body>
        <ModalComponents.Footer>
          <ModalComponents.CancelButton onClick={() => toggle(false)} />
          <ModalComponents.ConfirmButton
            label={
              !isSubmitting && !postOrPutGroupMutation.isLoading
                ? TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode
                  ? 'save'
                  : `${null == teamToEdit ? 'Add ' : 'Edit '} mentee`
                : 'Loading...'
            }
            type={'submit'}
            disabled={isSubmitting || postOrPutGroupMutation.isLoading}
          />
        </ModalComponents.Footer>
      </form>
    </Modal>
  );
}

interface Props extends Pick<ModalProps, 'toggle'> {
  teamToEdit?: TeamFormData | null;
}

export interface TeamFormData {
  id: string | null;
  name: string;
  members: Option[];
  focusedElement: Option | null;
  cohort: boolean;
}
