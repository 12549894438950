import React, { useState } from 'react';
import { PathwayGoals, PathwaysPeriods } from '../sections';
import useModal from '../../../hooks/useModal';
import { DevelopmentPeriodsModal } from '../modals';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getAcademicYears, getStrategies, postOrPutStrategy } from '../../../service/api';
import PathwayStrategies from '../sections/pathwayStrategies';
import { ROLE_SCHOOL_COORDINATOR, ROLE_SYS_ADMIN } from '../../../resources/roles';
import StrategyModal from '../modals/strategyModal';
import PathwaysAcademicYears from '../sections/pathwaysAcademicYears';
import { ACADEMIC_YEARS, ADMIN_GOALS, ADMIN_STRATEGIES } from '../../../service/queryKeys';
import { PeriodProps } from '../../pathway/pathwaysPage.types';
import PathwayPlans from '../sections/pathwayPlans';

const SchoolAdminPathwayView = ({ me }: { me: any }) => {
  const queryClient = useQueryClient();
  const [includeParam, setIncludeParam] = useState<string>();

  const [periodsModal, togglePeriodsModal, setPeriodsProps] = useModal((props: any) => (
    <DevelopmentPeriodsModal className={'w-100'} {...props} />
  ));

  const [adminStrategyModal, toggleAdminStrategyModal, setAdminStrategyProps] = useModal((props: any) => (
    <StrategyModal {...props} />
  ));

  const sysAdminStrategiesQuery = useQuery(
    [ADMIN_STRATEGIES, includeParam, 'school'],
    () => getStrategies(includeParam, undefined, 'school'),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      placeholderData: [],
      keepPreviousData: true,
    }
  );

  const academicYearsQuery = useQuery([ACADEMIC_YEARS], () => getAcademicYears(), {
    staleTime: Infinity,
    select: (data) => data.data,
    placeholderData: [],
  });

  return (
    <>
      <PathwaysAcademicYears data={academicYearsQuery.data!} />
      {periodsModal}
      {adminStrategyModal}

      <PathwaysPeriods
        academicYears={academicYearsQuery.data!}
        toggle={(period?: PeriodProps) => {
          setPeriodsProps({
            title: `${period ? 'Update term dates' : 'Add new term'}`,
            description: `For ${me.organisation}`,
            period,
          });
          togglePeriodsModal(true);
        }}
      />
      <PathwayStrategies
        role={ROLE_SCHOOL_COORDINATOR}
        data={sysAdminStrategiesQuery.data}
        isAdmin
        canDisable
        showDisabled={includeParam === 'disabled'}
        handleShowDisabled={() =>
          includeParam === 'disabled' ? setIncludeParam(undefined) : setIncludeParam('disabled')
        }
        toggle={(title: string, strategy: any, preventCloseOnClickOnMask = true) => {
          setAdminStrategyProps({
            title,
            strategy,
            role: ROLE_SCHOOL_COORDINATOR,
            size: 'lg',
            preventCloseOnClickOnMask,
          });
          toggleAdminStrategyModal(true);
        }}
      />
      <PathwayGoals role={ROLE_SCHOOL_COORDINATOR} strategies={sysAdminStrategiesQuery.data} />
      <PathwayPlans role={ROLE_SCHOOL_COORDINATOR} isAdmin />
    </>
  );
};

export default SchoolAdminPathwayView;
