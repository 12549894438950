import React from 'react';
import { VideoSection } from '../sections';
import { Section } from '../../common';
import { CommunityNewsfeed } from '../../index';
import MeetingsOverview from '../../greatTeachingTeams/components/MeetingsOverview';
import { Me } from '../../../@types/Entity/Me';
import GTTNiotLogo from '@app/asset/images/GTT_NIoT.png';
import { LinkStyled } from '../../../global/link/link.styled';
import { useQuery } from 'react-query';
import { THINKIFIC_SSO } from '../../../service/queryKeys';
import { getThinkificSSO } from '../../../service/api';
import { THINKIFIC_LINKS } from '../../../service/links';
import { niotAdminAccounts, niotRole } from '../../../resources/roles';

const NiotDashboard = ({ me }: { me: Me }) => {
  const thinkificSSO = useQuery(THINKIFIC_SSO, () => getThinkificSSO(), {
    select: (data: any) => data.data.data.link,
    refetchInterval: 120000, //Thinkifc token last for 2 mins,so we refetch the token here every 2 mins
    refetchIntervalInBackground: true,
  });

  const userTag = niotRole(me);

  const data = () => {
    if (me.is_early_career_teacher) {
      return {
        title: 'Welcome to your Early Careers Teacher Programme with the National Institute of Teaching (NIoT)',
        videoLink: 'https://vimeo.com/910031404/a4b742954d',
        content: (
          <Section size={'md'} containerClassName={'h-full'} className={'col-span-2'}>
            <VideoSection video={'https://vimeo.com/859747474/a8e121a39c'} />
            <br />
            <p className={'font-bold'}>
              We are thrilled to have you on board and can&apos;t wait to support you in your journey towards becoming
              an exceptional educator.
            </p>
            <br />
            <p className={'font-bold'}>
              As a participant in the NIoT Early Career Framework programme (ECF), you will gain invaluable skills and
              knowledge through our cutting-edge online tools.
            </p>
            <br />
            <p className={'font-bold'}>
              Start by watching your welcome video from Katy Micklewright, Head of ECF Faculty and then check out the
              ECF Programme Introduction.
            </p>
            <br />
            <div>
              <LinkStyled
                asButton
                mainColor={'primary'}
                href={thinkificSSO.isFetched ? thinkificSSO.data + THINKIFIC_LINKS['selfStudyEctCourse1'] : '#'}
                target={'_blank'}
                className={'mx-auto inline-block'}
              >
                Go to introduction
              </LinkStyled>
            </div>
            <br />
            <div className={'flex justify-center'}>
              <img src={GTTNiotLogo} width={'50%'} height={'auto'} alt={'niot logo'} />
            </div>
          </Section>
        ),
      };
    }

    if (me.mentor && !me.is_induction_lead) {
      return {
        title: 'Welcome to your Early Careers Mentor Programme with the National Institute of Teaching (NIoT)',
        videoLink: 'https://vimeo.com/910031533/b9d3765488',
        content: (
          <Section size={'md'} containerClassName={'h-full'} className={'col-span-2'}>
            <VideoSection video={'https://vimeo.com/859946578/ad508acdb5'} />
            <br />
            <p className={'font-bold'}>
              We value the role of ECT mentors extremely highly and have developed a programme for you which is
              respectful of your time and expertise.
            </p>
            <br />
            <p className={'font-bold'}>
              As a mentor on the NIoT Early Career Framework programme (ECF), you will have access to tools which allow
              you to support your ECT in developing their teaching skills and knowledge through our cutting-edge online
              tools.
            </p>
            <br />
            <p className={'font-bold'}>
              Start by watching your welcome video from Katy Micklewright, Head of ECF Faculty and then check out the
              ECF Programme Introduction.
            </p>
            <br />
            <div>
              <LinkStyled
                asButton
                href={thinkificSSO.isFetched ? thinkificSSO.data + THINKIFIC_LINKS['selfStudyMentorCourse1'] : '#'}
                target={'_blank'}
                className={'mx-auto inline-block'}
              >
                Go to introduction
              </LinkStyled>
            </div>
            <br />
            <div className={'my-8'}>
              <p className={'font-bold'}>
                The first session for Year 1 Mentors: onboarding catch-up session
                <br />
              </p>
              <p className={'text-sm mb-2'}>(If you were unable to attend)</p>
              <p className={''}>
                Please make sure you register your attendance at the catch-up by scanning the QR code and answering the
                questions during the session.
              </p>
              <br />
              <LinkStyled
                asButton
                href={thinkificSSO.isFetched ? thinkificSSO.data + THINKIFIC_LINKS['onboardingCatchup'] : '#'}
                target={'_blank'}
                className={'mx-auto inline-block'}
              >
                Catch-up
              </LinkStyled>
            </div>
            <br />
            <div className={'flex justify-center'}>
              <img src={GTTNiotLogo} width={'50%'} height={'auto'} alt={'niot logo'} />
            </div>
          </Section>
        ),
      };
    }

    if (me.is_induction_lead) {
      return {
        title: 'Welcome to your school’s Early Careers Programme with the National Institute of Teaching (NIoT).',
        welcomeVideo: 'https://vimeo.com/859903964/da821c1871',
        videoLink: 'https://vimeo.com/910031645/778579dfa8',
        content: (
          <Section size={'md'} containerClassName={'h-full'} className={'col-span-2'}>
            <VideoSection video={'https://vimeo.com/859903964/da821c1871'} />
            <br />
            <p className={'font-bold'}>
              We value the role of our induction tutors extremely highly and have developed a programme and platform to
              ensure that you can easily manage the progress of your ECTs in one place.
            </p>
            <br />
            <p className={'font-bold'}>
              To begin, please watch the welcome video from Katy Micklewright, Head of ECF Faculty.
            </p>
            <br />
            <div>
              <LinkStyled
                asButton
                href={
                  'https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/DPS_GTT%20Resources/2024%20Induction%20lead%20Introduction%20booklet.pdf'
                }
                target={'_blank'}
                className={'mx-auto inline-block'}
              >
                Introduction booklet 2024
              </LinkStyled>
            </div>
            <br />
            <div className={'flex justify-center'}>
              <img src={GTTNiotLogo} width={'50%'} height={'auto'} alt={'niot logo'} />
            </div>
          </Section>
        ),
      };
    }

    if (me.is_associate_college) {
      return {
        title: 'Welcome to the Early Careers Programme with the National Institute of Teaching (NIoT).',
        content: (
          <Section size={'md'} containerClassName={'h-full'} className={'col-span-3'}>
            <p className={'font-bold text-center'}>
              We value our Associate Colleges extremely highly and have developed a programme and platform to ensure
              that you can easily manage the progress of your ECTs and Mentors in one place.
            </p>
            <br />
            <div className={'flex justify-center my-8'}>
              <img src={GTTNiotLogo} width={'50%'} height={'auto'} alt={'niot logo'} />
            </div>
          </Section>
        ),
      };
    }

    if (me.is_regional_lead || me.is_niot || (me.is_niot && me.is_ceo_admin)) {
      return {
        title:
          me.is_niot && me.is_ceo_admin
            ? 'Welcome to our Early Careers and School Trust CEO Programmes with the Great Teaching Toolkit'
            : 'Welcome to our Early Careers Programme with the Great Teaching Toolkit',
        content: (
          <Section size={'md'} containerClassName={'h-full'} className={'col-span-3'}>
            <p className={'font-bold text-center'}>
              {me.is_niot && me.is_ceo_admin
                ? 'We’ve developed an integration between our programmes and the GTT platform to ensure you can easily manage the progress of your CEOs, Schools, ECTs and Mentors in one place.'
                : 'We’ve developed a programme and platform to ensure that you can easily manage the progress of your Schools, ECTs and Mentors in one place.'}
            </p>
            <br />
            <div className={'flex justify-center my-8'}>
              <img src={GTTNiotLogo} width={'50%'} height={'auto'} alt={'niot logo'} />
            </div>
          </Section>
        ),
      };
    }
  };

  return (
    <>
      <TagSection me={me} title={data()!.title} content={data()!.content} />
      {['INDUCTION_LEAD', 'MENTOR', 'ECT'].includes(userTag) ? (
        <div className="flex flex-col lg:flex-row gap-8 field-mb">
          <CommunityNewsfeed width={'lg'} />
          <Section size={null} headline={'GTT Tutorial for the ECF Programme'} className={'w-full lg:w-2/4'}>
            <VideoSection video={data()!.videoLink!} />
          </Section>
        </div>
      ) : niotAdminAccounts.includes(niotRole(me)) ? (
        <></>
      ) : (
        <CommunityNewsfeed width={'lg'} />
      )}
    </>
  );
};

const TagSection = ({ me, title, content }: { me: Me; title: string; content: React.ReactElement }) => {
  return (
    <>
      {(me.is_early_career_teacher || me.mentor || me.is_induction_lead) && (
        <div className={'block-with-sub-container mb-5'}>
          <MeetingsOverview />
        </div>
      )}
      <div
        className={`block-with-sub-container mb-5 ${
          niotAdminAccounts.includes(niotRole(me)) ? 'w-full xl:w-2/3 ' : ''
        }`}
      >
        <h5 className={`${niotAdminAccounts.includes(niotRole(me)) ? 'text-center ' : ''}`}>{title}</h5>
        <div className={'grid grid-cols-3 gap-4 mt-5'}>{content}</div>
      </div>
    </>
  );
};

export default NiotDashboard;
