import React, { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Field, FieldArray, Formik } from 'formik';
import {
  courses,
  DimensionInterface,
  DIMENSIONS,
  ElementInterface,
  ELEMENTS,
  LinkButtonsType,
  resourceInterface,
  resourceTypeInterface,
  resourceTypesArray,
  testDataOfSomeIcons,
} from '../resources/types';
import Selector from '../../../form/select/selector';
import Input from '../../../form/input/input';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DRAFT_RESOURCES, EMBED, RESOURCE, RESOURCES } from '../../../service/queryKeys';
import {
  addResourceContent,
  downloadResourceContent,
  getEmdedMetadData,
  updateResourceContent,
} from '../../../service/api';
import Button from '../../../global/button/button';
import { SelectOptionType } from '../../../service/types';
import Label from '../../../form/common/label';
import Datepicker from '../../../form/datepicker/datepicker';
import dayjs from 'dayjs';
import useModal from '../../../hooks/useModal';
import AddButton from '../../pathway/components/addButton';
import DefaultTable from '../../../global/table/defaultTable';
import { LinkStyled } from '../../../global/link/link.styled';
import ButtonIcon from '../../../global/buttonIcon/buttonIcon';
import { toast } from 'react-toastify';
import AddFileModal from '../modals/addFileModal';
import Choice from '../../../form/choice/choice';
import * as Yup from 'yup';
import IconListModal from '../modals/iconListModal';
import { PATHWAYS_STEP_TYPES } from '../../passport/modals/addDevelopmentActivityModal';
import Icon from '../../../global/icon/icon';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import ResourceCard from '../../algolia/components/resourceCard';
import { Section } from '../../common';
import CourseInfoPage from '../../algolia/components/textResourcePages/courseInfoPage';
import GuidePage from '../../algolia/components/textResourcePages/guidePage';
import InsetPackPage from '../../algolia/components/textResourcePages/insetPackPage';
import PosterCollectionPage from '../../algolia/components/textResourcePages/posterCollectionPage';
import TechniqueInfoPage from '../../algolia/components/textResourcePages/techniqueInfoPage';
import TextResourcesDefaultPage from '../../algolia/components/textResourcePages/defaultPage';
import Colorful from '@uiw/react-color-colorful';
import ResourceTextPage from '../../algolia/components/textResourcePages/resourceTextPage';
import { v4 as uuidV4 } from 'uuid';
import { ActionButton } from '../../../global/buttonIcon/common';

const NewResource = ({
  editMode = false,
  resource,
  cancelResource,
  closeModal,
}: {
  editMode: boolean;
  resource?: resourceInterface;
  cancelResource?: any;
  closeModal?: any;
}) => {
  function isValidUrl(string: any) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }

  const checkCategory = (type: string) => {
    return resourceTypesArray.find((resourceType: resourceTypeInterface) => resourceType.value === type)?.type;
  };

  const isDraft = editMode ? resource?.draft : false;

  const [previewState, setPreviewState] = useState<boolean>(false);
  const [banner, setBanner] = useState<any>();
  const [fetch, setFetch] = useState<string>('');
  const [icon, setIcon] = useState<any>(editMode && resource?.icon ? resource.icon : '');
  const [imageIcon, setImageIcon] = useState<any>(
    editMode
      ? resource?.image_icon && !resource?.icon
        ? 'image'
        : !resource?.image_icon && resource?.icon
        ? 'icon'
        : ''
      : ''
  );
  const [fetchedResource, setFetchedResource] = useState<any>();
  const [file, setFile] = useState<any>(null);
  const [type, setType] = useState<any>(editMode && resource?.type ? checkCategory(resource.type) : '');
  const [extended, setExtended] = useState<any>(
    editMode ? resourceTypesArray.find((type: resourceTypeInterface) => type.value === resource?.type)?.extended : ''
  );
  const [dataUsed, setDataUsed] = useState<boolean>(false);
  const [showIcons, setShowIcons] = useState<any>();

  const { data: fetchedData, refetch } = useQuery([EMBED, fetch], () => getEmdedMetadData(fetch), {
    select: (data) => data.data.data,
    placeholderData: null,
    keepPreviousData: false,
    enabled: !!fetch,
  });

  useEffect(() => {
    let isMounted = true;
    if (fetch) refetch();
    return () => {
      isMounted = false;
    };
  }, [fetch]);

  useEffect(() => {
    if (!editMode) {
      setIcon('');
      setImageIcon('');
      setFile('');
      setBanner('');
    }
  }, [type]);

  useEffect(() => {
    let isMounted = true;
    if (fetchedData)
      setFetchedResource({
        author: fetchedData?.author ? fetchedData.author : '',
        created_date: fetchedData?.created_at ? fetchedData.created_at : '',
        description: fetchedData?.description ? fetchedData.description : '',
        image_url: fetchedData?.image_url ? fetchedData.image_url : '',
        provider_image: fetchedData?.provider_image_url ? fetchedData.provider_image_url : '',
        provider_name: fetchedData?.provider_name ? fetchedData.provider_name : '',
        provider_url: fetchedData?.provider_url ? fetchedData.provider_url : '',
        title: fetchedData?.title ? fetchedData.title : '',
        url: fetchedData?.url ? fetchedData.url : '',
      });
    return () => {
      isMounted = false;
    };
  }, [fetchedData]);

  const filterElements = (dimensions: Array<string>) => {
    const array = ELEMENTS.filter((element: ElementInterface) => dimensions.includes(element.dimension_number));
    return array.map((element: ElementInterface) => {
      return {
        label: `${element.dimension_number}.${element.element_number}. ${element.element_name}`,
        value: `${element.dimension_number}.${element.element_number}`,
      };
    });
  };

  const [state, setState] = useState<EditorState>(
    editMode && resource?.content
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(resource?.content)))
      : EditorState.createEmpty()
  );

  const [modal, toggleModal, setModalProps] = useModal((props: any) => <AddFileModal setFile={setFile} {...props} />, {
    title: 'Add file',
  });

  const [iconModal, toggleIconModal, setIconModalProps] = useModal((props: any) => <IconListModal {...props} />, {
    title: 'Choose an icon',
  });

  const queryClient = useQueryClient();

  const addResource = useMutation(addResourceContent, {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE);
      queryClient.invalidateQueries(RESOURCES);
      queryClient.invalidateQueries(DRAFT_RESOURCES);
    },
  });

  const editResource = useMutation(updateResourceContent, {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE);
      queryClient.invalidateQueries(RESOURCES);
      queryClient.invalidateQueries(DRAFT_RESOURCES);
    },
  });

  const updateElements = (dimensions: any, elements: any) => {
    const currentDimensions = dimensions.map((dimension: any) => dimension.value);
    const availableElements = ELEMENTS.filter((element: ElementInterface) =>
      currentDimensions.includes(element.dimension_number)
    ).map((e: any) => e.elementId);
    const updatedElements = elements?.filter((element: any) => availableElements.includes(element));
    return updatedElements;
  };

  const slugify = (text: string) =>
    text
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');

  const textResource = (values: any) => {
    return {
      id: values?.id,
      type: values?.type,
      title: values?.title,
      image_icon: values?.image_icon,
      icon: icon,
      short_description: values?.short_description,
      estimated_time: values?.estimated_time,
      pathway_steps: values?.pathway_steps,
      keywords: values?.keywords,
      goals: values?.goals,
      parents: values?.parents,
      draft: values?.draft,
      url: values?.url,
      urls: values?.urls,
      slug: values?.slug,
      dimension: values?.dimensions,
      element: values?.elements,
      author: {
        name: values?.author?.name,
        avatar: values?.author?.avatar,
        organisation: values?.author?.organisation,
        title: values?.author?.title,
        published_date: values?.author?.published_date,
        organisation_url: values?.author?.organisation_url,
      },
      content: JSON.stringify(convertToRaw(state.getCurrentContent())),
      description: values?.description,
      banner: values?.banner,
      instructions: values?.instructions,
      suggestions: values?.suggestions,
      children: values?.children,
      name: values?.name,
      link_buttons: values?.link_buttons,
    };
  };

  return (
    <>
      {modal}
      {iconModal}
      <Formik
        validationSchema={Yup.object().shape({
          id: Yup.string(),
          type: Yup.string().required(),
          title: Yup.string().required(),
          image_icon: Yup.string(),
          icon: Yup.string(),
          short_description: Yup.string(),
          estimated_time: Yup.string(),
          pathway_steps: Yup.array().of(Yup.string()),
          keywords: Yup.array().of(Yup.string()),
          goals: Yup.array().of(Yup.string()),
          parents: Yup.array().of(Yup.string()),
          draft: Yup.boolean(),
          url: Yup.string(),
          urls: Yup.array().of(Yup.string()),
          slug: Yup.string(),
          dimensions: Yup.array().of(Yup.string()),
          elements: Yup.array().of(Yup.string()),
          author: Yup.object().shape({
            name: Yup.string(),
            avatar: Yup.string(),
            organisation: Yup.string(),
            title: Yup.string(),
            published_date: Yup.string(),
            organisation_url: Yup.string(),
          }),
          description: Yup.string(),
          banner: Yup.string(),
          instructions: Yup.array().of(Yup.string()),
          suggestions: Yup.array().of(Yup.string()),
          children: Yup.array().of(Yup.string()),
          name: Yup.string(),
          link_buttons: Yup.array(),
        })}
        initialValues={{
          id: editMode ? resource?.id ?? '' : '',
          type: editMode && resource?.type ? resource.type ?? '' : '',
          title: editMode && resource?.title ? resource.title ?? '' : '',
          image_icon: editMode && resource?.image_icon ? resource.image_icon ?? '' : '',
          icon: editMode && resource?.icon ? resource.icon ?? '' : '',
          short_description: editMode ? resource?.short_description ?? '' : '',
          estimated_time: editMode ? resource?.estimated_time ?? '' : '',
          pathway_steps: editMode ? resource?.pathway_steps ?? [] : [],
          keywords: editMode ? resource?.keywords ?? [] : [],
          goals: editMode ? resource?.goals ?? [] : [],
          parents: editMode ? resource?.parents ?? [] : [],
          draft: editMode && resource ? resource.draft : false,
          url: editMode ? resource?.url ?? '' : '',
          urls: editMode ? resource?.urls ?? [] : [],
          file: {
            id: editMode ? resource?.file?.id ?? '' : '',
            type: editMode ? resource?.file?.type ?? '' : '',
            name: editMode ? resource?.file?.name ?? '' : '',
            url: editMode ? resource?.file?.url ?? '' : '',
          },
          file_name: editMode ? resource?.file_name ?? '' : '',
          slug: editMode ? resource?.slug ?? '' : '',
          dimensions: editMode ? resource?.dimension ?? [] : [],
          elements: editMode
            ? resource?.element
              ? resource.element.map((resource: any) => resource.slice(0, 3))
              : []
            : [],
          author: {
            name: editMode ? resource?.author?.name ?? '' : '',
            avatar: editMode ? resource?.author?.avatar ?? '' : '',
            organisation: editMode ? resource?.author?.organisation ?? '' : '',
            title: editMode ? resource?.author?.title ?? '' : '',
            published_date: editMode ? resource?.author?.published_date ?? '' : '',
            organisation_url: editMode ? resource?.author?.organisation_url ?? '' : '',
          },
          content: editMode ? resource?.content ?? state : state,
          description: editMode ? resource?.description ?? '' : '',
          banner: editMode ? resource?.banner ?? '' : '',
          instructions: editMode ? resource?.instructions ?? [] : [],
          suggestions: editMode ? resource?.suggestions ?? [] : [],
          children: editMode ? resource?.children ?? [] : [],
          name: editMode ? resource?.name ?? '' : '',
          link_buttons: editMode ? resource?.resourceButtons ?? [] : [],
        }}
        isInitialValid={editMode}
        onSubmit={(data: resourceInterface) => {
          editMode
            ? (data.type === 'element' || type === 'file') && file
              ? addResource
                  .mutateAsync(data)
                  .then(() => {
                    toast.success('Resource edited');
                    closeModal(false);
                  })
                  .catch((error) => {
                    toast.error(error?.response?.data?.message ?? 'An error has occurred');
                  })
              : editResource
                  .mutateAsync(data)
                  .then(() => {
                    toast.success('Resource edited');
                    closeModal(false);
                  })
                  .catch((error) => {
                    toast.error(error.response.data.message ?? 'An error has occurred');
                  })
            : addResource
                .mutateAsync(data)
                .then(() => {
                  toast.success(data.draft ? 'Resource saved' : 'Resource added');
                  cancelResource(false);
                })
                .catch((error) => {
                  toast.error(error.response.data.message ?? 'An error has occurred');
                });
        }}
      >
        {({ values, setFieldValue, handleSubmit, isValid, resetForm }) => (
          <form className={'py-5'} onSubmit={handleSubmit}>
            {/*SELECT A CATEGORY TYPE OF THE RESOURCE*/}
            <div className="flex mb-8">
              <div className="m-auto">
                {!editMode || (editMode && type === 'web') ? (
                  <Button
                    disabled={editMode && type === 'web'}
                    className={'m-2'}
                    isOutline={type != 'web'}
                    onClick={() => {
                      setType('web');
                      resetForm();
                    }}
                  >
                    Web resource
                  </Button>
                ) : (
                  <></>
                )}
                {!editMode || (editMode && type === 'file') ? (
                  <Button
                    disabled={editMode && type === 'file'}
                    className={'m-2'}
                    isOutline={type != 'file'}
                    onClick={() => {
                      setType('file');
                      resetForm();
                    }}
                  >
                    File resource
                  </Button>
                ) : (
                  <></>
                )}
                {!editMode || (editMode && type === 'text') ? (
                  <Button
                    disabled={editMode && type === 'text'}
                    className={'m-2'}
                    isOutline={type != 'text'}
                    onClick={() => {
                      setType('text');
                      resetForm();
                    }}
                  >
                    {editMode && values.type === 'element' ? 'Element' : 'Text resource'}
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>

            {editMode && values.type === 'element' && (
              <div className={'flex'}>
                <div className={'mx-auto text-sm'}>
                  This is a unique element page. You cannot change the type of the resource.
                </div>
              </div>
            )}
            {/*SELECT TYPE OF RESOURCE*/}
            {type && (
              <>
                {editMode && values.type === 'element' ? (
                  <></>
                ) : (
                  <div className={'field-mb'}>
                    <Selector
                      required
                      options={resourceTypesArray.filter(
                        (resourceType: resourceTypeInterface) =>
                          resourceType.type === type && !resourceType.hidden && resourceType
                      )}
                      label={'Resource type'}
                      placeholder={'Select resource type'}
                      id={'resource_type_input'}
                      value={
                        values.type
                          ? resourceTypesArray.find((type: resourceTypeInterface) => type.value === values.type)
                          : null
                      }
                      onChange={(option: resourceTypeInterface) => {
                        setExtended(option.extended);
                        option == null ? setFieldValue('type', null) : setFieldValue('type', option.value);
                      }}
                    />
                  </div>
                )}

                {/*URL EXISTS FOR WEB AND TEXT TYPES, IT'S ON TOP OF THE PAGE BECAUSE FOR WEB WE USE FETCHER TO GATHER AS MUCH METADATA AS POSSIBLE BEFORE FILLING THE FIELDS OURSELVES */}
                {values.type && type === 'web' ? (
                  <div className="field-mb">
                    <Input
                      label={'Url'}
                      id={'resources_url_input'}
                      type={'text'}
                      placeholder={'Paste url here'}
                      value={values.url}
                      onChange={(option) => {
                        setFieldValue('url', option.target.value);
                        setDataUsed(false);
                      }}
                    />
                    {extended && (
                      <>
                        <Button
                          disabled={values.url === '' || !isValidUrl(values.url) || dataUsed}
                          className={'m-2'}
                          onClick={() => {
                            values.url && setFetch(values.url);
                          }}
                        >
                          {'Fetch data'}
                        </Button>
                        <Button
                          disabled={!fetch || !fetchedData || dataUsed}
                          className={'m-2'}
                          onClick={() => {
                            setFieldValue('title', fetchedResource?.title);
                            fetchedResource?.image_url &&
                              (setImageIcon('image'), setFieldValue('image_icon', fetchedResource.image_url));
                            setFieldValue('short_description', fetchedResource?.description);
                            setFieldValue('author.name', fetchedResource?.author);
                            setFieldValue('author.organisation_url', fetchedResource?.provider_url);
                            setFieldValue('author.published_date', fetchedResource?.created_date ?? '');
                            setFieldValue('author.organisation', fetchedResource?.provider_name);
                            setDataUsed(true);
                          }}
                        >
                          {'Use fetched values (BETA)'}
                        </Button>
                        <div>
                          <span className={'text-sm text-warning'}>
                            WARNING! Using fetched values will{' '}
                            <u>
                              <em>overwrite</em>
                            </u>{' '}
                            your data in following fields: Title, Image, Short description, Author name, Organisation
                            url, Date of publication, Author organisation.
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                {/*FIELDS FOR ALL TYPES*/}
                {values.type && (
                  <>
                    {/*FIELDS REQUIRED*/}
                    <>
                      <div className={'field-mb'}>
                        <Input
                          maxLength={125}
                          label={'Title'}
                          id={'resource_title_input'}
                          type={'text'}
                          placeholder={'Title'}
                          required
                          value={values.title}
                          onChange={(option) => {
                            setFieldValue('title', option.target.value);
                            setFieldValue(
                              'slug',
                              values.type === 'course_info'
                                ? slugify(option.target.value) + '-course'
                                : slugify(option.target.value)
                            );
                          }}
                        />
                      </div>
                      <div className="field-mb">
                        <Choice
                          id={'image_icon'}
                          type={'radio'}
                          checked={imageIcon === 'image'}
                          onChange={() => {
                            setImageIcon('image');
                            setIcon('');
                            setFieldValue('image_icon', '');
                          }}
                        >
                          Image
                        </Choice>
                        <Choice
                          id={'image_icon'}
                          type={'radio'}
                          checked={imageIcon === 'icon'}
                          onChange={() => {
                            setImageIcon('icon');
                            setIcon('');
                            setFieldValue('image_icon', '');
                          }}
                        >
                          Icon
                        </Choice>
                      </div>
                      <div className={'field-mb'}>
                        {imageIcon === 'image' && (
                          <Input
                            label={'Image'}
                            id={'resource_image_input'}
                            type={'text'}
                            placeholder={'Please paste the image url'}
                            required={imageIcon === 'image'}
                            value={values.image_icon}
                            onChange={(option) => {
                              setFieldValue('image_icon', option.target.value);
                              setFieldValue('icon', '');
                              setIcon('');
                            }}
                          />
                        )}
                        {imageIcon === 'icon' && (
                          <div className="flex">
                            <Button
                              className={'my-2 mr-4'}
                              size={'sm'}
                              onClick={() => {
                                setIconModalProps({
                                  setIcon: setIcon,
                                });
                                toggleIconModal(true);
                              }}
                            >
                              Choose an icon
                            </Button>
                            {icon && (
                              <>
                                <Icon className={'border'} elementSize={60} icon={icon} />
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </>

                    {/*FIELDS OPTIONAL*/}
                    <>
                      <div className={'field-mb'}>
                        <Input
                          maxLength={1000}
                          label={'Short description'}
                          id={'resource_description_input'}
                          type={'textarea'}
                          placeholder={'Short description'}
                          value={values.short_description}
                          onChange={(option) => {
                            setFieldValue('short_description', option.target.value);
                          }}
                        />
                      </div>
                      <div className={'field-mb'}>
                        <Input
                          label={'Estimated reading time'}
                          id={'resource_estimated_reading_time_input'}
                          type={'number'}
                          placeholder={'Estimated reading time'}
                          value={values.estimated_time}
                          onChange={(option) => {
                            option.target.value
                              ? setFieldValue('estimated_time', option.target.value)
                              : setFieldValue('estimated_time', '0');
                          }}
                        />
                      </div>
                      <div className={'field-mb'}>
                        <Label id={'resource_pathways_label'} label={'Pathway steps'} />
                        <Selector
                          id={'resource_pathways_input'}
                          placeholder={'Add pathway step(s)'}
                          value={values.pathway_steps?.map((value: any) => {
                            return {
                              label: value,
                              value,
                            };
                          })}
                          onChange={(options: any) => {
                            setFieldValue(
                              'pathway_steps',
                              options == null ? [] : options.map((option: SelectOptionType) => option.value)
                            );
                          }}
                          options={PATHWAYS_STEP_TYPES}
                          isMulti
                          isClearable
                        />
                      </div>
                      <div className={'field-mb'}>
                        <Label id={'resources_keywords_input_label'} label={'Keywords'} />
                        <Selector
                          id={'resources_keywords_input'}
                          placeholder={'Add keyword(s)'}
                          value={values.keywords?.map((value: any) => {
                            {
                              return {
                                label: value,
                                value,
                              };
                            }
                          })}
                          onChange={(options: any) =>
                            setFieldValue(
                              'keywords',
                              options == null ? [] : options.map((option: SelectOptionType) => option.value)
                            )
                          }
                          isCreatableSelect
                          isMulti
                          isClearable
                        />
                      </div>
                      <div className={'field-mb'}>
                        <Label id={'resources_goals_input_label'} label={'Goals'} />
                        <Selector
                          id={'resources_goals_input'}
                          placeholder={'Add goal(s)'}
                          value={values.goals?.map((value: any) => {
                            {
                              return {
                                label: value,
                                value,
                              };
                            }
                          })}
                          onChange={(options: any) =>
                            setFieldValue(
                              'goals',
                              options == null ? [] : options.map((option: SelectOptionType) => option.value)
                            )
                          }
                          isCreatableSelect
                          isMulti
                          isClearable
                        />
                      </div>
                      <div className={'field-mb'}>
                        <Label id={'resources_parents_input_label'} label={'Parents'} />
                        <Selector
                          id={'resources_parents_input'}
                          placeholder={'Add parent(s)'}
                          value={values.parents?.map((value: any) => {
                            {
                              return {
                                label: value,
                                value,
                              };
                            }
                          })}
                          onChange={(options: any) =>
                            setFieldValue(
                              'parents',
                              options == null ? [] : options.map((option: SelectOptionType) => option.value)
                            )
                          }
                          isCreatableSelect
                          isMulti
                          isClearable
                        />
                      </div>
                      {(!editMode || isDraft) && (
                        <div className={'field-mb'}>
                          <Choice
                            type={'checkbox'}
                            id={'community_post_save_as_draft'}
                            name={'Save as draft'}
                            label={'Save as draft'}
                            checked={values.draft}
                            onChange={() => setFieldValue('draft', !values.draft)}
                          />
                        </div>
                      )}

                      {/*FIELDS FOR TEXT RESOURCES*/}
                      {values.type && type === 'text' && (
                        <>
                          <div className={'field-mb'}>
                            <Label id={'resources_urls_input_label'} label={'Urls'} />
                            <Selector
                              id={'resources_urls_input'}
                              placeholder={'Add url(s)'}
                              value={values.urls?.map((value: any) => {
                                {
                                  return {
                                    label: value,
                                    value,
                                  };
                                }
                              })}
                              onChange={(options: any) =>
                                setFieldValue(
                                  'urls',
                                  options == null ? [] : options.map((option: SelectOptionType) => option.value)
                                )
                              }
                              isCreatableSelect
                              isMulti
                              isClearable
                            />
                          </div>
                          <div className="field-mb">
                            <Input
                              label={'Slug'}
                              id={'resources_slug_input'}
                              type={'text'}
                              placeholder={'Slug for a resource page'}
                              value={values.slug}
                              onChange={(option) => {
                                setFieldValue('slug', option.target.value);
                              }}
                            />
                          </div>
                        </>
                      )}

                      {/*FIELD REQUIRED FOR FILE TYPE*/}
                      {type === 'file' || (editMode && type === 'text' && values.type === 'element') ? (
                        <>
                          <div className="field-mb">
                            <AddButton
                              //disabled={file || editMode}
                              label={'File'}
                              required
                              onClick={() => {
                                toggleModal(true);
                              }}
                            />
                          </div>
                          <div className="field-mb">
                            {(file?.type || resource?.file_name) && (
                              <div className={'resource_file'}>
                                <DefaultTable
                                  id={`resources_file_table`}
                                  columns={[
                                    { id: 'name', label: 'Name' },
                                    { id: 'actions', label: 'Actions' },
                                  ]}
                                  rows={[
                                    {
                                      id: 'row_resource_table',
                                      cells: [
                                        {
                                          id: 'name',
                                          content: (
                                            <>
                                              {editMode ? (
                                                file ? (
                                                  file?.file.path
                                                ) : (
                                                  <LinkStyled
                                                    onClick={() => {
                                                      downloadResourceContent(
                                                        resource?.id ?? '',
                                                        resource?.file_name ?? ''
                                                      )
                                                        .then(() => {
                                                          toast.success('File downloaded');
                                                        })
                                                        .catch((error: any) => {
                                                          toast.error(
                                                            error.response.data.message ?? 'An error has occurred.'
                                                          );
                                                        });
                                                    }}
                                                    target={'_blank'}
                                                  >
                                                    {resource?.file_name}
                                                  </LinkStyled>
                                                )
                                              ) : (
                                                file?.file?.path
                                              )}
                                            </>
                                          ),
                                        },
                                        {
                                          id: 'actions',
                                          class: 'actions',
                                          content: (
                                            <ul className={'actions-list gap-2'}>
                                              <ButtonIcon
                                                disabled={editMode}
                                                mainColor={'danger'}
                                                icon={'Trash'}
                                                label={'Delete'}
                                                onClick={() => {
                                                  setFile(null);
                                                }}
                                              />
                                            </ul>
                                          ),
                                        },
                                      ],
                                    },
                                  ]}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {/*OPTIONAL FOR ALL TYPES*/}
                      <div className={'field-mb'}>
                        <Label id={'resource_dimensions_label'} label={'Dimensions'} />
                        <Selector
                          id={'resource_dimensions_input'}
                          placeholder={'Add dimension(s)'}
                          value={values.dimensions?.map((value: any) => {
                            return {
                              label: `Dimension ${value}`,
                              value,
                            };
                          })}
                          onChange={(options: any) => {
                            setFieldValue(
                              'dimensions',
                              options == null ? [] : options.map((option: SelectOptionType) => option.value)
                            );
                            (options == null || options.length === 0) && setFieldValue('elements', []);
                            setFieldValue('elements', updateElements(options, values.elements));
                          }}
                          options={DIMENSIONS.map((dimension: DimensionInterface) => {
                            return {
                              label: `${dimension.dimension_number}. ${dimension.dimension_name}`,
                              value: dimension.dimension_number,
                            };
                          })}
                          isMulti
                          isClearable
                        />
                      </div>
                      {values.dimensions && values.dimensions.length > 0 && (
                        <div className={'field-mb'}>
                          <Label id={'resource_elements_label'} label={'Elements'} />
                          <Selector
                            id={'resource_elements_input'}
                            placeholder={'Add element(s)'}
                            value={values.elements?.map((value: any) => {
                              return {
                                label: `Element ${value}`,
                                value,
                              };
                            })}
                            onChange={(options: any) =>
                              setFieldValue(
                                'elements',
                                options == null ? [] : options.map((option: SelectOptionType) => option.value)
                              )
                            }
                            options={filterElements(values.dimensions)}
                            isMulti
                            isClearable
                          />
                        </div>
                      )}
                    </>

                    {/*FIELDS OPTIONAL FOR EXTENDED TYPES (WEB AND FILE RESOURCES)*/}
                    {extended && (
                      <>
                        <div className={'field-mb'}>
                          <Input
                            label={'Author name'}
                            id={'resources_author_name_input'}
                            type={'text'}
                            placeholder={'Author name'}
                            value={values?.author?.name}
                            onChange={(option) => {
                              setFieldValue('author.name', option.target.value);
                            }}
                          />
                        </div>
                        <div className={'field-mb'}>
                          <Input
                            label={'Author image'}
                            id={'resources_author_image_input'}
                            type={'text'}
                            placeholder={'Author image'}
                            value={values?.author?.avatar}
                            onChange={(option) => {
                              setFieldValue('author.avatar', option.target.value);
                            }}
                          />
                        </div>
                        <div className={'field-mb'}>
                          <Input
                            label={'Author organisation'}
                            id={'resources_author_organisation_input'}
                            type={'text'}
                            placeholder={'Author organisation'}
                            value={values?.author?.organisation}
                            onChange={(option) => {
                              setFieldValue('author.organisation', option.target.value);
                            }}
                          />
                        </div>
                        <div className={'field-mb'}>
                          <Input
                            label={'Author title'}
                            id={'resources_author_title_input'}
                            type={'text'}
                            placeholder={'Author title'}
                            value={values?.author?.title}
                            onChange={(option) => {
                              setFieldValue('author.title', option.target.value);
                            }}
                          />
                        </div>
                        <div className="field-mb">
                          <Datepicker
                            placeholder={'Date of publication'}
                            label={'Date of publication'}
                            id={'resources_date_of_publication_value'}
                            selected={
                              values.author?.published_date ? dayjs(values.author?.published_date).toDate() : undefined
                            }
                            value={
                              values.author?.published_date && dayjs(values.author?.published_date).format('DD/MM/YYYY')
                            }
                            onChange={(date: Date) => {
                              setFieldValue('author.published_date', dayjs(date).format('YYYY/MM/DD'));
                            }}
                          />
                        </div>
                        <div className={'field-mb'}>
                          <Input
                            label={'Organisation url'}
                            id={'resources_organisation_url_input'}
                            type={'text'}
                            placeholder={'Organisation url'}
                            value={values?.author?.organisation_url}
                            onChange={(option) => {
                              setFieldValue('author.organisation_url', option.target.value);
                            }}
                          />
                        </div>
                      </>
                    )}
                    {/*FIELDS OPTIONAL FOR TEXT RESOURCES*/}
                    {type === 'text' && (
                      <>
                        <div className={'field-mb min-h-fit'}>
                          <Label id={'resource_content_input_label'} label={'Content'} required={true} />
                          <span className={'text-sm text-warning'}>
                            WARNING! Please don&apos;t use the{' '}
                            <u>
                              <em>Code</em>
                            </u>{' '}
                            option in the dropdown below!
                          </span>
                          <Editor
                            toolbarHidden={false}
                            toolbar={{
                              options: ['inline', 'blockType', 'list', 'image', 'link', 'emoji'],
                              inline: {
                                options: ['bold', 'italic', 'underline', 'monospace'],
                                bold: { className: 'bordered-option-classname' },
                                italic: { className: 'bordered-option-classname' },
                                underline: { className: 'bordered-option-classname' },
                                code: { className: 'bordered-option-classname' },
                              },
                              link: {
                                options: ['link'],
                              },
                            }}
                            wrapperStyle={{ minHeight: '500px', maxHeight: '500px', backgroundColor: 'white' }}
                            wrapperClassName={'border border-black rounded'}
                            toolbarClassName={'m-4'}
                            editorStyle={{ minHeight: '400px', maxHeight: '400px' }}
                            editorClassName={'m-4'}
                            onEditorStateChange={setState}
                            editorState={state}
                          />
                        </div>
                        <div className={'field-mb'}>
                          <Input
                            maxLength={1000}
                            label={'Long description'}
                            id={'resource_description_input'}
                            type={'textarea'}
                            placeholder={'Long description'}
                            value={values.description}
                            onChange={(option) => {
                              setFieldValue('description', option.target.value);
                            }}
                          />
                        </div>
                        <div className="field-mb">
                          <Label id={'banner_choice'} label={'Banner'} />
                          <Choice
                            id={'banner_hex_color'}
                            type={'radio'}
                            checked={banner === 'hex'}
                            onChange={() => {
                              setBanner('hex');
                              setFieldValue('banner', '#ff8e00');
                            }}
                          >
                            Hex value (color)
                          </Choice>
                          <Choice
                            id={'banner_url'}
                            type={'radio'}
                            checked={banner === 'url'}
                            onChange={() => {
                              setBanner('url');
                              setFieldValue('banner', '');
                            }}
                          >
                            Url (image)
                          </Choice>
                        </div>
                        {banner === 'url' ? (
                          <div className={'field-mb'}>
                            <Input
                              id={'resource_banner_input'}
                              type={'text'}
                              placeholder={'Banner image url'}
                              value={values.banner}
                              onChange={(option) => {
                                setFieldValue('banner', option.target.value);
                              }}
                            />
                          </div>
                        ) : banner === 'hex' ? (
                          <div className={'flex gap-8'}>
                            <Colorful
                              color={values.banner}
                              disableAlpha={true}
                              onChange={(color) => {
                                setFieldValue('banner', color.hexa);
                              }}
                            />
                            <div style={{ backgroundColor: values.banner }} className="w-40 h-40 rounded-sm flex">
                              <div className="m-auto w-2/3">
                                <Input
                                  id={'resource_banner_input'}
                                  type={'text'}
                                  value={values.banner}
                                  onChange={(option) => {
                                    setFieldValue('banner', option.target.value);
                                  }}
                                />
                              </div>
                              {/*<p className="m-auto font-bold">
                                  {values.banner}
                                </p>*/}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className={'field-mb'}>
                          <Label id={'resources_instructions_input_label'} label={'Instructions'} />
                          <Selector
                            id={'resources_instructions_input'}
                            placeholder={'Add instructions'}
                            value={values.instructions?.map((value: any) => {
                              {
                                return {
                                  label: value,
                                  value,
                                };
                              }
                            })}
                            onChange={(options: any) =>
                              setFieldValue(
                                'instructions',
                                options == null ? [] : options.map((option: SelectOptionType) => option.value)
                              )
                            }
                            isCreatableSelect
                            isMulti
                            isClearable
                          />
                        </div>
                        <div className={'field-mb'}>
                          <Label id={'resources_suggestions_input_label'} label={'Suggestions'} />
                          <Selector
                            id={'resources_suggestions_input'}
                            placeholder={'Add suggestions'}
                            value={values.suggestions?.map((value: any) => {
                              {
                                return {
                                  label: value,
                                  value,
                                };
                              }
                            })}
                            onChange={(options: any) =>
                              setFieldValue(
                                'suggestions',
                                options == null ? [] : options.map((option: SelectOptionType) => option.value)
                              )
                            }
                            isCreatableSelect
                            isMulti
                            isClearable
                          />
                        </div>
                        <div className={'field-mb'}>
                          <Label id={'resources_children_input_label'} label={'Children'} />
                          <Selector
                            id={'resources_children_input'}
                            placeholder={'Add children'}
                            value={values.children?.map((value: any) => {
                              {
                                return {
                                  label: value,
                                  value,
                                };
                              }
                            })}
                            onChange={(options: any) =>
                              setFieldValue(
                                'children',
                                options == null ? [] : options.map((option: SelectOptionType) => option.value)
                              )
                            }
                            isCreatableSelect
                            isMulti
                            isClearable
                          />
                        </div>
                        {values.type === 'course_info' && (
                          <>
                            <div className={'field-mb'}>
                              <Label id={'resources_course_input_label'} label={'Course'} />
                              <Selector
                                id={'resources_course_input'}
                                placeholder={'Choose course'}
                                value={courses.find((course: any) => course.value === values.name)}
                                options={courses}
                                onChange={(option: any) => setFieldValue('name', option.value)}
                                isClearable={false}
                              />
                            </div>
                          </>
                        )}
                        {values.type === 'template' && (
                          <>
                            <div className={'mt-10'}>
                              <div className={'mb-4'}>
                                <Label id={'link_buttons_input_label'} label={'Link buttons'} />
                                <FieldArray
                                  name="link_buttons"
                                  render={(arrayHelpers) => (
                                    <div>
                                      <Button
                                        onClick={() =>
                                          arrayHelpers.push({
                                            id: uuidV4(),
                                            name: '',
                                            url: '',
                                            icon: '',
                                          })
                                        }
                                      >
                                        Add link button
                                      </Button>
                                      {values.link_buttons &&
                                        values.link_buttons.length > 0 &&
                                        values.link_buttons.map((link_button: LinkButtonsType, index) => (
                                          <div key={index} className={'my-2'}>
                                            <div className="flex flex-grow gap-4">
                                              <AddButton
                                                onClick={() => {
                                                  if (showIcons) setShowIcons(null);
                                                  else setShowIcons(link_button.id);
                                                }}
                                                hideLabel
                                                disabled={showIcons && showIcons != link_button.id}
                                                label={'icon'}
                                              />
                                              {link_button.icon ? (
                                                <>
                                                  <Icon
                                                    className={'my-auto'}
                                                    elementSize={40}
                                                    icon={link_button.icon}
                                                  />
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              <Input
                                                className={'w-full'}
                                                onChange={(e: any) =>
                                                  setFieldValue(`link_buttons[${index}].value`, e.target.value)
                                                }
                                                value={link_button.value}
                                                id={'input_name'}
                                                placeholder={link_button.value ? link_button.value : 'Button name'}
                                              />
                                              <Input
                                                className={'w-full'}
                                                onChange={(e: any) =>
                                                  setFieldValue(`link_buttons[${index}].url`, e.target.value)
                                                }
                                                id={'input_url'}
                                                value={link_button.url}
                                                placeholder={link_button.url ? link_button.url : 'Button link'}
                                              />
                                              <div className="flex ml-auto gap-2 justify-end">
                                                <ActionButton.Remove
                                                  className={'m-auto'}
                                                  onClick={() => arrayHelpers.remove(index)}
                                                />
                                              </div>
                                            </div>
                                            {showIcons === link_button.id && (
                                              <div className={'flex flex-wrap'}>
                                                {testDataOfSomeIcons.map((icon: any) => (
                                                  <Icon
                                                    key={Math.random()}
                                                    onClick={() => {
                                                      setFieldValue(`link_buttons[${index}].icon`, icon);
                                                      setShowIcons(null);
                                                    }}
                                                    elementSize={30}
                                                    className={'m-4'}
                                                    icon={icon}
                                                    container={false}
                                                  />
                                                ))}
                                              </div>
                                            )}
                                          </div>
                                        ))}
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                            <div className={'field-mb'}></div>
                          </>
                        )}
                      </>
                    )}
                    {/*BUTTONS AT THE BOTTOM*/}
                    <div className={'flex'}>
                      <div className={'m-auto'}>
                        <Button onClick={() => setPreviewState(!previewState)} className={'m-2'}>
                          Preview
                        </Button>
                        <Button
                          className={'m-2'}
                          disabled={!isValid}
                          type="submit"
                          onClick={() => {
                            file && setFieldValue('file', file);
                            imageIcon === 'icon'
                              ? (setFieldValue('icon', icon), setFieldValue('image_icon', ''))
                              : imageIcon === 'image' && setFieldValue('icon', '');
                            values?.author?.published_date === null && setFieldValue('author.published_date', '');
                            setFieldValue('content', JSON.stringify(convertToRaw(state.getCurrentContent())));
                          }}
                        >
                          {editMode && !isDraft
                            ? 'Save changes'
                            : (!editMode && values.draft) || (editMode && isDraft && values.draft)
                            ? 'Save as a draft'
                            : 'Publish'}
                        </Button>
                        {!editMode && (
                          <Button onClick={() => cancelResource(false)} className={'m-2'}>
                            Cancel
                          </Button>
                        )}
                      </div>
                    </div>
                    {/*PREVIEW*/}
                    {previewState && (
                      <>
                        <ResourceCard
                          preview
                          draft={values?.draft}
                          classes={'mt-8 lg:w-1/2 w-full m-auto'}
                          resource={{
                            objectID: values?.id,
                            type: values?.type,
                            title: values?.title,
                            image_icon: values?.image_icon,
                            icon: icon,
                            short_description: values?.short_description,
                            estimated_time: values?.estimated_time,
                            pathway_steps: values?.pathway_steps,
                            keywords: values?.keywords,
                            goals: values?.goals,
                            parents: values?.parents,
                            draft: values?.draft,
                            url: values?.url,
                            urls: values?.urls,
                            slug: values?.slug,
                            dimension: values?.dimensions,
                            element: values?.elements,
                            author: {
                              author_name: values?.author?.name,
                              avatar: values?.author?.avatar,
                              organisation: values?.author?.organisation,
                              author_title: values?.author?.title,
                              published_date: values?.author?.published_date,
                              organisation_url: values?.author?.organisation_url,
                            },
                            content: JSON.stringify(convertToRaw(state.getCurrentContent())),
                            description: values?.description,
                            banner: values?.banner,
                            instructions: values?.instructions,
                            suggestions: values?.suggestions,
                            children: values?.children,
                            name: values?.name,
                            link_buttons: values?.resourceButtons,
                            file_name: null, //Edit for file
                            file_content_type: null, //Edit for file
                          }}
                        ></ResourceCard>
                        <>
                          {type === 'text' && (
                            <Section className={'border-2 mt-16'}>
                              <>
                                {values.type === 'course_info' ? (
                                  <CourseInfoPage resource={textResource(values)} />
                                ) : values.type === 'guide' ? (
                                  <GuidePage resource={textResource(values)} />
                                ) : values.type === 'inset_pack' ? (
                                  <InsetPackPage resource={textResource(values)} />
                                ) : values.type === 'poster_collection' ? (
                                  <PosterCollectionPage resource={textResource(values)} />
                                ) : values.type === 'self_study' ? (
                                  <>No page for self_study yet</>
                                ) : values.type === 'technique_info' ? (
                                  <TechniqueInfoPage resource={textResource(values)} />
                                ) : values.type === 'template' ? (
                                  <ResourceTextPage resource={textResource(values)} />
                                ) : (
                                  <>
                                    Resource&apos;s type not found. Here&apos;s some info{' '}
                                    <TextResourcesDefaultPage resource={textResource(values)} />
                                  </>
                                )}{' '}
                              </>
                            </Section>
                          )}
                        </>
                      </>
                    )}
                  </>
                )}
                {!values.type && !editMode && (
                  <Button onClick={() => cancelResource(false)} className={'m-2'}>
                    Cancel
                  </Button>
                )}
              </>
            )}
          </form>
        )}
      </Formik>
    </>
  );
};

export default NewResource;
