import React, { HTMLAttributes, MutableRefObject, useEffect, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import { ModalProps } from './modal.types';
import { MaskStyled, ModalWrapperStyled } from './modal.styled';
import { GLOBAL_ICONS } from '../../../resources/vars';
import Portal from '../../portal/portal';
import ButtonIcon from '../../buttonIcon/buttonIcon';

const Modal = ({
  preventCloseOnClickOnMask,
  preventClose,
  title,
  description,
  size = 'md',
  position = 'top',
  fullHeight = false,
  open,
  toggle,
  children,
  ...other
}: ModalProps & HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const [active, setActive] = useState(false);

  const mask = React.useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    const body = document.body;

    // useRef
    const { current } = mask;

    // SO TRANSITIONS ARE TRIGGERED
    const onTransitionEnd = () => setActive(open);

    // CLOSE WHEN ESC KEY IS PRESSED
    const onEscKey = (event: KeyboardEvent) => {
      !preventClose && 'Escape' === event.code && toggle(false);
    };

    /**
     * Bug fix - preventing modal from closing when clicking on mask
     */

    // CLOSE WHEN CLICK ON MASK
    const onClickOnMask = (event: MouseEvent) => {
      if (preventCloseOnClickOnMask != true) {
        event.target === current && toggle(false);
      }
    };

    // NEEDED IN ORDER TO TRIGGER ENDING TRANSITION
    if (open) {
      body.style.overflow = 'hidden';

      setTimeout(() => {
        setActive(open);
      }, 10);
    }

    // ADDS EVENT LISTENERS
    if (current) {
      current.addEventListener('transitionend', onTransitionEnd);
      current.addEventListener('mousedown', onClickOnMask);
      window.addEventListener('keyup', onEscKey);
    }

    // REMOVES EVENT LISTENERS
    return () => {
      if (current) {
        current.removeEventListener('transitionend', onTransitionEnd);
        current.removeEventListener('mousedown', onClickOnMask);
      }

      body.removeAttribute('style');
      window.removeEventListener('keyup', onEscKey);
    };
  }, [open, toggle]);

  return (
    <React.Fragment>
      {(open || active) && (
        <Portal className={'portal-modal'}>
          <FocusTrap
            active={open || active}
            focusTrapOptions={{
              initialFocus: '#modal-wrapper',
            }}
          >
            <MaskStyled id={'modal-wrapper'} ref={mask} tabIndex={0} data-toggle={active && open && 'true'}>
              <ModalWrapperStyled
                data-id={'content'}
                $size={size}
                $position={position}
                $fullHeight={fullHeight}
                {...other}
              >
                <div className={'flex flex-col h-full'}>
                  <div className="block-container flex justify-between items-center">
                    <div>
                      {title && <h5>{title}</h5>}
                      {description && <i className={'text-sm'}>{description}</i>}
                    </div>
                    <div className={preventClose ? 'hidden' : ''}>
                      <ButtonIcon
                        isFree
                        label={'Close'}
                        icon={GLOBAL_ICONS.close}
                        size={'sm'}
                        onClick={() => toggle(false)}
                      />
                      <span className={'block text-center text-xs text-muted'} aria-hidden={'true'}>
                        ESC
                      </span>
                    </div>
                  </div>
                  {children}
                </div>
              </ModalWrapperStyled>
            </MaskStyled>
          </FocusTrap>
        </Portal>
      )}
    </React.Fragment>
  );
};

Modal.displayName = 'Modal';

export default Modal;
