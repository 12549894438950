import React, { useState } from 'react';
import { ResourceCardInterface } from '../resources/types';
import { ActionButton } from '../../../global/buttonIcon/common';
import useModal from '../../../hooks/useModal';
import EditResource from '../../resourcePage/modals/editResourceModal';
import ConfirmDeleteResourceModal from '../../resourcePage/modals/confirmDeleteResourceModal';
import { useMutation, useQueryClient } from 'react-query';
import { clickResource, downloadResourceContent, removeResourceContent } from '../../../service/api';
import { DRAFT_RESOURCES, RESOURCE, RESOURCES } from '../../../service/queryKeys';
import { LinkStyled, RouterStyled } from '../../../global/link/link.styled';
import { resourceTypeInterface, resourceTypesArray } from '../../resourcePage/resources/types';
import Icon from '../../../global/icon/icon';
import { GLOBAL_ICONS } from '../../../resources/vars';
import Tooltip from '../../../global/tooltip/tooltip';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import routeBuilder from '../../../service/routeBuilder';
import { dimensions, DimensionType } from '../../mfgtPage/resources/resources';
import { Link } from 'react-router-dom';
import ElementSquare from '../../mfgtPage/components/elementSquare';

const ResourceCard = ({
  resource,
  admin = false,
  editor = false,
  draft = false,
  classes,
  preview = false,
  uniqueKey,
}: ResourceCardInterface) => {
  const [modal, toggleModal, setModalProps] = useModal((props: any) => <EditResource {...props} />, {
    title: 'Edit resource',
  });

  const [favourite, setFavourite] = useState<boolean>(false);

  const checkCategory = (type: string) => {
    return findType(type)?.type;
  };

  const findType = (type: string) => {
    return resourceTypesArray.find((r: resourceTypeInterface) => r.value === type);
  };

  const countElements = (elements?: any, dimension?: any) => {
    if (elements?.length > 0)
      return elements?.filter((element: any) => element && element.charAt(0) === dimension).length;
    else return 0;
  };

  const getElements = (elements: any, dimension: any) => {
    if (elements?.length > 0)
      return elements
        ?.map((element: any) => element && element.slice(0, 3))
        ?.filter((element: any) => element && element.charAt(0) === dimension);
    else return;
  };

  const retrieveUniqueTags = (dimensions?: any, elements?: any) => {
    const uniqueValues: Array<string> = [];
    if (dimensions) {
      if (dimensions.includes('1')) {
        countElements(elements, '1') > 3 || countElements(elements, '1') === 0
          ? uniqueValues.push('1')
          : uniqueValues.push(getElements(elements, '1'));
      }
      if (dimensions.includes('2')) {
        countElements(elements, '2') > 3 || countElements(elements, '2') === 0
          ? uniqueValues.push('2')
          : uniqueValues.push(getElements(elements, '2'));
      }
      if (dimensions.includes('3')) {
        countElements(elements, '3') > 2 || countElements(elements, '3') === 0
          ? uniqueValues.push('3')
          : uniqueValues.push(getElements(elements, '3'));
      }
      if (dimensions.includes('4')) {
        countElements(elements, '4') > 5 || countElements(elements, '4') === 0
          ? uniqueValues.push('4')
          : uniqueValues.push(getElements(elements, '4'));
      }
    }
    return uniqueValues.flat();
  };

  const queryClient = useQueryClient();

  const removeResource = useMutation(removeResourceContent, {
    onSuccess: () => {
      queryClient.invalidateQueries(RESOURCE);
      queryClient.invalidateQueries(RESOURCES);
      queryClient.invalidateQueries(DRAFT_RESOURCES);
    },
  });

  const clickResources = useMutation(clickResource, {
    onSuccess: () => {
      /*Do nothing*/
    },
  });

  const [confirmDeleteModal, toggleConfirmDeleteModal, setConfirmDeleteModalProps] = useModal((props: any) => (
    <ConfirmDeleteResourceModal {...props} />
  ));

  return (
    <React.Fragment key={uniqueKey}>
      {modal}
      {confirmDeleteModal}
      <div
        id={`resourceCard_${resource?.objectID}`}
        className={`align-middle rounded-md my-2 ${resource?.type != 'element' && ' py-2 border-2'} ${
          classes ? classes : ''
        }`}
      >
        {resource?.type === 'element' ? (
          <>
            <Link to={`${routeBuilder('modelForGreatTeaching').generate()}#${resource.slug && resource.slug}`}>
              <div
                className={`flex w-full h-full rounded-md flex cursor-pointer border-2 border-${
                  resource?.dimension[0] === '1'
                    ? 'red'
                    : resource?.dimension[0] === '2'
                    ? 'green'
                    : resource?.dimension[0] === '3'
                    ? 'blue'
                    : resource?.dimension[0] === '4'
                    ? 'purple'
                    : 'black'
                }-800`}
                style={{ backgroundColor: resource?.banner, minHeight: '140px' }}
              >
                <div className="mt-8 ml-12 text-white font-semibold min-w-fit">
                  <div className={'w-full'}>
                    {resource?.element[0].slice(0, 3)} {resource.title}
                  </div>
                  <div className={'text-sm'}>
                    {dimensions
                      .find((dimension: DimensionType) => dimension.number.toString() === resource?.dimension[0])
                      ?.title.toUpperCase()}
                  </div>
                </div>
                <div className="ml-auto w-1/4 overflow-hidden">
                  <img src={resource?.image_icon} className={'ml-auto w-full aspect-square opacity-75'} />
                </div>
              </div>
            </Link>
            {/*{!preview && <div className="flex">
                <div className={'-mt-12 pt-2 ml-auto z-10'} style={{ color: 'white' }}>
                    <ResourceCardMoreAction uniqueColor={'white'} favourite={favourite} setFavourite={setFavourite}/>
                </div>
            </div>}*/}
            {admin && (
              <div className={'flex'}>
                <div className={'mr-2'}>Edit element resource</div>
                <ActionButton.Edit
                  disabled={!editor}
                  onClick={() => (
                    setModalProps({
                      id: draft ? resource?.id : resource?.objectID,
                      size: 'xl',
                      preventCloseOnClickOnMask: true,
                    }),
                    toggleModal(true)
                  )}
                />
              </div>
            )}
          </>
        ) : (
          <>
            {/*style={{ minHeight: '150px', height: '1px' }}*/}
            <div style={{ minHeight: '120px' }} className={'flex flex-col min-h-fit'}>
              <div className={'flex-grow'}>
                <div className="flex">
                  <div className={'flex justify-between gap-2'}>
                    <div
                      className={`hidden sm:block ml-2 mt-2 ${!resource?.image_icon && !resource?.icon ? 'ml-12' : ''}`}
                    >
                      {resource?.image_icon && !resource?.icon ? (
                        <img
                          className="bg-surface-50 inline-block object-cover h-10 w-10 mt-2 rounded"
                          src={resource?.image_icon}
                          alt={resource?.image_icon}
                        />
                      ) : resource?.icon && !resource?.image ? (
                        <Icon className={'mb-auto'} elementSize={40} icon={resource.icon} />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className={'flex justify-between mb-1 flex'}>
                      <div className={'leading-tight mb-1'}>
                        <div className={'mt-2 font-semibold'}>{resource?.title}</div>
                        <div className={'text-sm flex'}>
                          {findType(resource.type)?.value === 'blog_web' ? (
                            <>
                              {resource?.author?.author_name ? <>{resource.author.author_name}</> : ''}
                              {resource?.author?.organisation ? (
                                <>&#8226;{resource.author.organisation.toUpperCase()}</>
                              ) : (
                                ''
                              )}
                              {resource?.estimated_time > 0 ? <>&#8226;{Math.ceil(resource.estimated_time)} min</> : ''}
                            </>
                          ) : findType(resource.type)?.value === 'podcast' ? (
                            <>
                              PODCAST
                              {resource?.author?.organisation ? <>&#8226;{resource.author.organisation}</> : ''}
                              {resource?.estimated_time > 0 ? <>&#8226;{Math.ceil(resource.estimated_time)} min</> : ''}
                            </>
                          ) : (
                            <>
                              {findType(resource.type)?.label.toUpperCase()}
                              {resource?.estimated_time > 0 ? <>&#8226;{Math.ceil(resource.estimated_time)} min</> : ''}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-auto mr-1 px-2">
                    <div className="flex">
                      {resource?.type && resource.type != 'element' && checkCategory(resource.type) === 'text' && (
                        <Tooltip content={'Generate page'}>
                          <RouterStyled to={`#${resource.slug && resource.slug}`}>
                            <div className="w-12 h-12 bg-transparent rounded-full border-2 flex justify-center items-center text-center">
                              <Icon elementSize={40} icon={GLOBAL_ICONS['view']} />
                            </div>
                          </RouterStyled>
                        </Tooltip>
                      )}
                      {resource?.type && checkCategory(resource.type) === 'web' && (
                        <>
                          <Tooltip content={'Copy link'}>
                            <LinkStyled id={`copy_${resource?.id}`}>
                              <CopyToClipboard
                                text={resource?.url}
                                onCopy={() => toast.success('Copied the link')}
                                options={{ format: 'text' }}
                              >
                                <div className="w-12 h-12 bg-transparent rounded-full border-2 flex justify-center items-center text-center">
                                  <Icon elementSize={40} icon={GLOBAL_ICONS['copy']} />
                                </div>
                              </CopyToClipboard>
                            </LinkStyled>
                          </Tooltip>
                          <Tooltip content={'Open in a new tab'}>
                            <LinkStyled
                              href={resource?.url}
                              target={'_blank'}
                              id={`open_${resource?.id}`}
                              onClick={() => {
                                resource?.objectID && clickResources.mutateAsync(resource?.objectID);
                              }}
                            >
                              <div className="w-12 h-12 bg-transparent rounded-full border-2 flex justify-center items-center text-center">
                                <Icon elementSize={40} icon={GLOBAL_ICONS['view']} />
                              </div>
                            </LinkStyled>
                          </Tooltip>
                        </>
                      )}
                      {resource?.type && checkCategory(resource.type) === 'file' && (
                        <Tooltip content={'Download file'}>
                          <LinkStyled
                            onClick={() => {
                              downloadResourceContent(draft ? resource?.id : resource?.objectID, resource?.file_name)
                                .then(() => {
                                  toast.success('File downloaded');
                                })
                                .catch((error: any) => {
                                  toast.error(error.response.data.message ?? 'An error has occurred.');
                                });
                            }}
                            target={'_blank'}
                            id={`download_${resource?.id}`}
                          >
                            <div className="w-12 h-12 bg-transparent rounded-full border-2 flex justify-center items-center text-center">
                              <Icon elementSize={40} icon={GLOBAL_ICONS['downloadFile']} />
                            </div>
                          </LinkStyled>
                        </Tooltip>
                      )}
                      {admin && (
                        <div className={'ml-2 mr-2'}>
                          <div className={'flex'}>
                            <p className="mx-auto bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded border border-red-400">
                              Admin
                            </p>
                          </div>
                          <div className={'flex gap-2'}>
                            <>
                              <ActionButton.Edit
                                disabled={!editor}
                                onClick={() => (
                                  setModalProps({
                                    id: draft ? resource?.id : resource?.objectID,
                                    size: 'xl',
                                    preventCloseOnClickOnMask: true,
                                  }),
                                  toggleModal(true)
                                )}
                              />
                              <ActionButton.Remove
                                disabled={!editor}
                                onClick={() => {
                                  setConfirmDeleteModalProps({
                                    handleSubmit: () =>
                                      draft
                                        ? resource?.id && removeResource.mutateAsync(resource?.id)
                                        : resource?.objectID && removeResource.mutateAsync(resource?.objectID),
                                  });
                                  toggleConfirmDeleteModal(true);
                                }}
                              />
                            </>
                          </div>
                          {draft && (
                            <div>
                              <span className="mt-4 bg-yellow-100 text-yellow-800 text-md font-medium mr-2 px-2.5 py-0.5 rounded border border-yellow-400">
                                Unpublished
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {resource?.short_description && (
                  <div
                    className={
                      'pl-2 ml-12 mr-8  max-h-12 text-base mb-1.5 overflow-hidden text-ellipsis whitespace-normal line-clamp-2'
                    }
                  >
                    {resource.short_description}
                  </div>
                )}
              </div>
              <div className={'bottom-0'}>
                <div className={'mt-auto ml-14 flex flex-row gap-1 flex-wrap'}>
                  {retrieveUniqueTags(resource?.dimension, resource?.element).map((value: any, index: number) => (
                    <React.Fragment key={index}>
                      <ElementSquare value={value} />
                    </React.Fragment>
                  ))}
                  {/*{!preview && (
                    <div className={'ml-auto mt-auto'}>
                      <ResourceCardMoreAction favourite={favourite} setFavourite={setFavourite}/>
                    </div>
                  )}*/}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};
export default ResourceCard;
